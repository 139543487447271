import { ChangeEvent, useRef, useState, useEffect } from "react";
import { FormControl, InputLabel, Select, TextField, Button, MenuItem } from "@mui/material";
import styles from "./Unify.module.css";
import { useAppSelector, useAppDispatch } from "../hooks";
import { userSelectionActions } from "../store/userSelection";
import { CsvFileDto, CsvRowDto, CsvSetDto, CsvDto, CsvFixedColumn } from "../store/unify";
import { unifyActions } from "../store/unify";
import { S3InternalBlobStore, JiraTicketId, FileListEntry } from "../providers/s3";
import PrettyFileListEntry from "./FileEntry";

// Icons
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import RefreshIcon from "@mui/icons-material/Refresh";

import { submitPwlUnifyRequest } from "../providers/lambda-services";

import { customAlphabet } from "nanoid";
import { nolookalikesSafe } from "nanoid-dictionary";
const nanoid = customAlphabet(nolookalikesSafe);

function FixedColumn(props: { colName: string; colValue: string; remove: () => void }) {
    return (
        <div className={styles.fixedColumn}>
            <div>{`${props.colName}: ${props.colValue}`}</div>
            <ClearIcon onClick={props.remove} />
        </div>
    );
}

function FixedColumns(props: { fileId?: string; rowId?: string; dto: CsvDto }) {
    const dispatch = useAppDispatch();
    const [newFixedColName, setNewFixedColName] = useState("");
    const [newFixedColValue, setNewFixedColValue] = useState("");

    const updateFixedColumns = (fixedColumns: CsvFixedColumn[]) => {
        dispatch(unifyActions.updateFixedColumns({ fileId: props.fileId, rowId: props.rowId, fixedColumns }));
    };
    const addFixedColumn = (fixedColumn: CsvFixedColumn) => {
        const alreadyExists = props.dto.fixed_columns?.find(col => col.column_name === fixedColumn.column_name);
        if (alreadyExists) {
            return;
        }
        const newFixedColumns = [...(props.dto.fixed_columns || []), fixedColumn];
        updateFixedColumns(newFixedColumns);
    };
    const removeFixedColumn = (colName: string) => {
        const newFixedColumns = (props.dto.fixed_columns || []).filter(col => col.column_name !== colName);
        updateFixedColumns(newFixedColumns);
    };
    return (
        <div className={styles.fixedColumns}>
            <div className={styles.form}>
                <b>Fixed columns</b>
            </div>
            <div className={styles.form}>
                {(props.dto.fixed_columns || []).map(col => {
                    return (
                        <FixedColumn
                            key={col.column_name}
                            colName={col.column_name}
                            colValue={col.column_value}
                            remove={() => {
                                removeFixedColumn(col.column_name);
                            }}
                        />
                    );
                })}
            </div>
            <div>
                <div className={styles.newFixedColumn}>
                    <div className={styles.form}>
                        <TextField
                            id="colName"
                            label="Name"
                            variant="outlined"
                            size="small"
                            value={newFixedColName}
                            onChange={event => setNewFixedColName(event.target.value)}
                        />
                    </div>
                    <div className={styles.form}>
                        <TextField
                            id="colValue"
                            label="Value"
                            variant="outlined"
                            size="small"
                            value={newFixedColValue}
                            onChange={event => setNewFixedColValue(event.target.value)}
                        />
                    </div>
                    <div>
                        <AddIcon
                            onClick={() => {
                                addFixedColumn({ column_name: newFixedColName, column_value: newFixedColValue });
                                setNewFixedColName("");
                                setNewFixedColValue("");
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

function CsvFile(props: { fileNames: string[]; fileId: string; rowId: string; dto: CsvFileDto }) {
    const [selectedFileName, setSelectedFileName] = useState<string>(props.dto.fileName || "");
    const dispatch = useAppDispatch();
    const removeFile = () => {
        dispatch(unifyActions.removeFile({ fileId: props.fileId, rowId: props.rowId }));
    };
    const setFile = (fileName: string) => {
        setSelectedFileName(fileName);
        dispatch(unifyActions.updateFile({ fileId: props.fileId, rowId: props.rowId, fileDto: { fileName } }));
    };
    return (
        <div className={styles.csvFile}>
            <div className={styles.form}>
                <FormControl fullWidth>
                    <InputLabel size="small" id="selectedFile">
                        Selected file
                    </InputLabel>
                    <Select
                        labelId="selectedFile"
                        id="selectedFile"
                        label="Selected File"
                        size="small"
                        value={selectedFileName}
                        onChange={(event: any) => {
                            setFile(event.target.value);
                        }}>
                        {props.fileNames.map(fileName => {
                            return (
                                <MenuItem key={fileName} value={fileName}>
                                    {fileName}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>
            <div className={styles.form}>
                <TextField
                    id="columns"
                    label="Columns"
                    variant="outlined"
                    onChange={() => {}}
                    size="small"
                    value={props.dto.columns?.join(",")}
                />
            </div>
            <div>
                <FixedColumns fileId={props.fileId} rowId={props.rowId} dto={props.dto} />
            </div>
            <div className={styles.form}>
                <DeleteForeverIcon onClick={removeFile} />
            </div>
        </div>
    );
}

function CsvRow(props: { fileNames: string[]; rowId: string; dto: CsvRowDto }) {
    const dispatch = useAppDispatch();
    const [joinKey, setJoinKey] = useState<string>("");

    function addFile() {
        dispatch(unifyActions.updateFile({ fileId: nanoid(4), rowId: props.rowId, fileDto: { file: null } }));
    }
    function removeRow() {
        dispatch(unifyActions.removeRow({ rowId: props.rowId }));
    }
    function updateJoinKey(join_key: string | undefined) {
        if (!join_key) {
            return;
        }
        const newDto = { ...props.dto, join_key };
        dispatch(unifyActions.updateRow({ rowId: props.rowId, rowDto: newDto }));
    }

    // Update join key after 1 second of inactivity
    // This is to avoid re-rendering the whole component on every key stroke
    useEffect(() => {
        const timeOutId = setTimeout(() => {
            updateJoinKey(joinKey);
        }, 1000);
        return () => clearTimeout(timeOutId);
    }, [joinKey]);

    return (
        <div className={styles.csvRow}>
            <div className={styles.csvRowItems}>
                {Object.entries(props.dto.files).map(([key, value]) => {
                    return (
                        <CsvFile fileNames={props.fileNames} key={key} fileId={key} rowId={props.rowId} dto={value} />
                    );
                })}
                <div className={styles.form}>
                    <Button variant="contained" onClick={addFile}>
                        Add File
                    </Button>
                </div>
            </div>
            {Object.keys(props.dto.files).length > 1 && (
                <>
                    <div className={styles.form}>
                        <TextField
                            id="join_key"
                            label="Join Key"
                            variant="outlined"
                            size="small"
                            onChange={(event: any) => setJoinKey(event.target.value)}
                            value={joinKey}
                        />
                    </div>
                    <div>
                        <FixedColumns rowId={props.rowId} dto={props.dto} />
                    </div>
                </>
            )}
            <div>
                <div className={styles.form}>
                    <DeleteForeverIcon onClick={removeRow} />
                </div>
            </div>
        </div>
    );
}

function CsvSet(props: { fileNames: string[]; dto: CsvSetDto }) {
    const dispatch = useAppDispatch();
    function addRow() {
        dispatch(unifyActions.updateRow({ rowId: nanoid(4), rowDto: { files: {}, join_key: "" } }));
    }
    return (
        <div className={styles.csvSet}>
            {Object.entries(props.dto.rows).map(([key, value]) => {
                return <CsvRow fileNames={props.fileNames} key={key} rowId={key} dto={value} />;
            })}
            <div>
                <FixedColumns dto={props.dto} />
            </div>
            <div className={styles.form}>
                <Button variant="contained" onClick={addRow}>
                    Add Row
                </Button>
            </div>
        </div>
    );
}

export class UnifyS3Store extends S3InternalBlobStore<string> {
    constructor(client: string, ticket: JiraTicketId) {
        super("unify", client, ticket);
    }
    resultFileName() {
        return "result.csv";
    }

    resultUrl() {
        return this.getS3Url(this.resultFileName());
    }
}

function getS3Store(association: string, ticketNumber: string) {
    return new UnifyS3Store(association, new JiraTicketId(ticketNumber));
}

const UNIFY_FILE_NAME = "unify.json";

function Unify() {
    const dispatch = useAppDispatch();
    const [fileNames, setFileNames] = useState<FileListEntry<string>[]>([]);
    const ticketNumber = useAppSelector(state => state.userSelection.ticketNumber);
    const csvSet = useAppSelector(state => state.unify.csvSet);
    const selectedAssociation = useAppSelector(state => state.userSelection.association);
    const fileRef = useRef<HTMLInputElement>(null);
    function getStore() {
        return getS3Store(selectedAssociation, ticketNumber);
    }
    async function listFiles() {
        const files = await getStore().listFileNames();
        console.log(files);
        setFileNames(files);
    }
    function handleFileChange() {
        return async function (e: ChangeEvent<HTMLInputElement>) {
            if (e.target.files) {
                const selectedFile = e.target.files[0];
                if (!selectedFile || !selectedFile.name || fileNames.map(f => f.fileName).includes(selectedFile.name)) {
                    return;
                }
                await getStore().uploadFile(selectedFile, selectedFile.name);
                setFileNames([...fileNames, { fileName: selectedFile.name }]);
            }
        };
    }
    async function removeFile(fileName: string) {
        await getStore().deleteFile(fileName);
        setFileNames(fileNames.filter(f => f.fileName !== fileName));
    }
    async function downloadFile(fileName: string) {
        await getStore().downloadFile(fileName);
    }
    useEffect(() => {
        if (!ticketNumber || !JiraTicketId.isValidId(ticketNumber)) {
            return;
        }
        // Update file list after 1 second of inactivity
        const timeOutId = setTimeout(() => {
            listFiles();
        }, 1000);
        return () => clearTimeout(timeOutId);
    }, [ticketNumber]);

    function setTicketNumber(ticketNumber: string) {
        dispatch(userSelectionActions.setTicketNumber(ticketNumber));
    }

    function marshalCsvSet(moreFixedColumns: CsvFixedColumn[] = []) {
        const store = getS3Store(selectedAssociation, ticketNumber);
        const rows = Object.entries(csvSet.rows).map(([key, value]) => {
            const files = Object.entries(value.files).map(([key, value]) => {
                const file_url = store.getS3Url(value.fileName);
                const { fixed_columns, columns } = value;
                return { file_url, fixed_columns, columns };
            });
            const { join_key, fixed_columns } = value;
            return { files, join_key, fixed_columns };
        });
        const fixed_columns = [...(csvSet.fixed_columns || []), ...moreFixedColumns];
        return { rows, fixed_columns, result_url: store.resultUrl() };
    }

    async function saveConfig() {
        // Upload the config file for easy refresh
        const store = getS3Store(selectedAssociation, ticketNumber);
        const file = new File([JSON.stringify(csvSet)], UNIFY_FILE_NAME);
        await store.uploadFile(file, UNIFY_FILE_NAME);
    }
    async function loadConfig() {
        const store = getS3Store(selectedAssociation, ticketNumber);
        const content = await store.getFileContents(UNIFY_FILE_NAME);
        const csvSet = JSON.parse(content);
        console.log(csvSet);
        dispatch(unifyActions.setCsvSet({ csvSet }));
    }

    async function submit() {
        const marshalledCsvSet = marshalCsvSet([{ column_name: "ticket", column_value: ticketNumber }]);
        console.log(JSON.stringify(marshalledCsvSet));
        const result = await submitPwlUnifyRequest(marshalledCsvSet);
        console.log(result);
        await saveConfig();
        await listFiles();
    }
    async function handleResultDownload() {
        const store = getS3Store(selectedAssociation, ticketNumber);
        await store.downloadFile(store.resultFileName());
    }
    return (
        <div className={styles.root}>
            <div className={styles.form}>
                Guide
                <ul>
                    <li>
                        Read the{" "}
                        <a href="https://simplygrowza.atlassian.net/wiki/spaces/PEOPLEFLOW/pages/87392257/Unifying+CSVs">
                            doc
                        </a>{" "}
                    </li>
                    <li>Select client</li>
                    <li>Specify ticket number</li>
                    <li>
                        Upload files (exiting files will automatically be fetched using <b>client</b> and <b>ticket</b>{" "}
                        )
                    </li>
                    <li>Arrange the files!</li>
                    <li>Submit and download</li>
                </ul>
            </div>
            <div className={styles.form}>
                <TextField
                    id="ticketNumber"
                    label="Ticket Number"
                    variant="outlined"
                    onChange={(e: any) => setTicketNumber(e.target.value)}
                    value={ticketNumber}
                />
            </div>
            <div className={styles.form}>
                <div className={styles.form}>
                    <div className={styles.form}>
                        <b>Upload files first</b>
                    </div>
                    {fileNames.map(f => {
                        return (
                            <div className={styles.form} key={f.fileName}>
                                <PrettyFileListEntry file={f} />{" "}
                                <CloudDownloadIcon onClick={() => downloadFile(f.fileName)} />{" "}
                                <DeleteForeverIcon onClick={() => removeFile(f.fileName)} />
                                {f.fileName === UNIFY_FILE_NAME && <RefreshIcon onClick={loadConfig} />}
                            </div>
                        );
                    })}
                    <div className={styles.form}>
                        <input
                            ref={fileRef}
                            type="file"
                            onInput={handleFileChange()}
                            onClick={() => {
                                if (fileRef.current) {
                                    fileRef.current.value = "";
                                }
                            }}
                        />{" "}
                    </div>
                </div>
            </div>
            <div className={styles.unify}>
                <CsvSet dto={csvSet} fileNames={fileNames.map(f => f.fileName)} />
            </div>
            <div>
                <Button variant="contained" onClick={submit}>
                    Submit
                </Button>
            </div>
            <div className={styles.form}>
                <Button variant="contained" onClick={handleResultDownload}>
                    Download
                </Button>
            </div>
        </div>
    );
}

export default Unify;
