import { createSlice } from "@reduxjs/toolkit";

export type CsvFixedColumn = {
    column_name: string;
    column_value: string;
};

export type CsvDto = {
    fixed_columns?: CsvFixedColumn[];
    columns?: string[];
};

export type CsvFileDto = {
    fileName: string;
    skip_rows: number;
} & CsvDto;

export type CsvRowDto = {
    files: Record<string, CsvFileDto>;
    join_key: string;
} & CsvDto;

export type CsvSetDto = {
    rows: Record<string, CsvRowDto>;
} & CsvDto;

const initialState: { csvSet: CsvSetDto } = { csvSet: { rows: {} } };

const unify = createSlice({
    name: "unify",
    initialState,
    reducers: {
        removeFile(state, action) {
            const { rowId, fileId } = action.payload;
            delete state.csvSet.rows[rowId].files[fileId];
        },
        updateFile(state, action) {
            const { rowId, fileId, fileDto } = action.payload;
            state.csvSet.rows[rowId].files[fileId] = fileDto;
        },
        removeRow(state, action) {
            const { rowId } = action.payload;
            delete state.csvSet.rows[rowId];
        },
        updateRow(state, action) {
            const { rowId, rowDto } = action.payload;
            state.csvSet.rows[rowId] = rowDto;
        },
        updateFixedColumns(state, action) {
            const { rowId, fileId, fixedColumns } = action.payload;
            if (!rowId && !fileId) {
                state.csvSet.fixed_columns = fixedColumns;
            }
            if (rowId && !fileId) {
                state.csvSet.rows[rowId].fixed_columns = fixedColumns;
            }
            if (rowId && fileId) {
                state.csvSet.rows[rowId].files[fileId].fixed_columns = fixedColumns;
            }
        },
        setCsvSet(state, action) {
            const { csvSet } = action.payload;
            state.csvSet = csvSet;
        },
    },
});

export const unifyActions = unify.actions;
export default unify.reducer;
