import styles from "./FileEntry.module.css";
import { FileListEntry } from "../providers/s3";
import dayjs from "dayjs";
import moment from "moment";

function PrettyFileListEntry(props: { file: FileListEntry<string> }) {
    function prettyDate(date: Date | undefined) {
        const d = dayjs(date);
        const ago = moment(date).fromNow();
        return d.format("YYYY-MM-DD HH:mm:ss") + " (" + ago + ")";
    }
    return (
        <span>
            <span className={styles.date}>{prettyDate(props.file.lastModified)}</span> &nbsp; {props.file.fileName}
        </span>
    );
}

export default PrettyFileListEntry;
