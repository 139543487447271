import { createSlice } from "@reduxjs/toolkit";

export const TOOL = {
    ABOUT: "about",
    PROFILE_INDEX: "profileIndex",
    INDEX_STATS: "indexStats",
    PROFILE: "profile",
    ETL: "etl",
    BILLING: "billing",
    EXPORTS: "exports",
    UNIFY: "unify",
    TRANSFORM: "transform",
    VALIDATE: "validate",
    PLAN_BULK_ACTION: "planBulkAction",
} as const;

type ObjectValues<T> = T[keyof T];
export type Tool = ObjectValues<typeof TOOL>;

interface UserSelectionState {
    tool: Tool;
    association: string;
    idPassport: string;
    ticketNumber: string;
}

const initialState: UserSelectionState = {
    tool: TOOL.TRANSFORM,
    association: "peopleflow",
    idPassport: "",
    ticketNumber: "PF-",
};

const userSelection = createSlice({
    name: "userSelection",
    initialState,
    reducers: {
        setTool: (state, action) => {
            state.tool = action.payload;
        },
        setAssociation: (state, action) => {
            state.association = action.payload;
        },
        setIdPassport: (state, action) => {
            state.idPassport = action.payload;
        },
        setTicketNumber: (state, action) => {
            state.ticketNumber = action.payload;
        },
    },
});

export const userSelectionActions = userSelection.actions;

export default userSelection.reducer;
