import React from "react";
import ReactDOM from "react-dom/client";
import { Amplify } from "aws-amplify";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store/index";
import { Provider } from "react-redux";

import { getAwsConfig } from "./cloud-config";

const API_ENV = process.env.REACT_APP_API_ENV || "prod";
const DATA_ENV_FOR_LOCAL = "prod";
export const awsConfig = getAwsConfig(API_ENV, DATA_ENV_FOR_LOCAL);
Amplify.configure(awsConfig);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
