import { useState, useEffect } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import { EtlEntry } from "../models/Record";
import {
    queryJobs,
    getJobTasks,
    deleteJob,
    getTaskResultRetrievers,
    getTaskResults,
    placeJob,
} from "../providers/lambda-services";

import { useAppSelector } from "../hooks";

import styles from "./Etl.module.css";
import JsonDisplay from "./JsonDisplay";

function JsonPopover(props: { text: string; payload: any }) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div>
            <Typography
                aria-owns={open ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}>
                {props.text}
            </Typography>
            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: "none",
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus>
                <Typography sx={{ p: 1 }}>
                    <JsonDisplay data={props.payload}></JsonDisplay>
                </Typography>
            </Popover>
        </div>
    );
}

function ResultHandler(props: { association: string; jobSk: string; taskKind: string; resultRetrieverName: string }) {
    const [params, setParams] = useState<any>("");
    const [results, setResults] = useState<any>();

    const downloadTxtFile = (retrieverResult: { payload: string; filetype: string }) => {
        console.log(retrieverResult);
        const element = document.createElement("a");
        const file = new Blob([retrieverResult.payload], {
            type: "text/plain",
        });
        element.href = URL.createObjectURL(file);
        element.download = `${props.association}_${props.jobSk}_${props.taskKind}_${props.resultRetrieverName}.${retrieverResult.filetype}`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    };

    function clearResults() {
        setResults(undefined);
    }

    function fetchResultsHandler(handlerName: string, params: any) {
        return async function () {
            const results = await getTaskResults(props.association, props.jobSk, props.taskKind, handlerName, params);
            if (results.show) {
                if (results.filetype === "json") {
                    setResults(JSON.parse(results.payload));
                } else {
                    setResults(results.payload);
                }
            } else {
                downloadTxtFile(results);
            }
        };
    }

    useEffect(() => {
        setResults(undefined);
    }, [props.jobSk]);

    return (
        <div>
            <div key={props.resultRetrieverName} className={styles.handler}>
                <Button variant="contained" onClick={fetchResultsHandler(props.resultRetrieverName, params)}>
                    Get
                </Button>
                <TextField
                    id="params"
                    label="params"
                    variant="outlined"
                    onChange={e => setParams([e.target.value])}
                    size="small"
                    value={params}
                />
            </div>
            {results && (
                <div>
                    <JsonDisplay data={results}></JsonDisplay>{" "}
                    <div className={styles.clear} onClick={clearResults}>
                        Clear
                    </div>
                </div>
            )}
        </div>
    );
}

function ResultHandlers(props: { association: string; jobSk: string; taskKind: string }) {
    const [resultRetrieverNames, setResultRetrieverNames] = useState<Array<string>>([]);
    const [selectedRetriever, setSelectedRetriever] = useState<string>("");

    function fetchTaskResultsHandlers(jobSk: string, taskKind: string) {
        return async () => {
            const resultRetrievers = await getTaskResultRetrievers(props.association, jobSk, taskKind);
            setResultRetrieverNames(resultRetrievers);
            setSelectedRetriever(resultRetrievers[0]);
        };
    }

    useEffect(() => {
        fetchTaskResultsHandlers(props.jobSk, props.taskKind)();
    }, [props.jobSk, props.taskKind]);

    return (
        <div className={styles.results}>
            <div>{props.taskKind}</div>
            <div className={styles.retriever_select}>
                <FormControl>
                    <InputLabel id="retriever">Retriever</InputLabel>
                    <Select
                        labelId="retriever"
                        id="retriever-select"
                        value={selectedRetriever}
                        label="Retriever"
                        onChange={e => setSelectedRetriever(e.target.value)}>
                        {resultRetrieverNames.map(resultRetrieverName => {
                            return <MenuItem value={resultRetrieverName}>{resultRetrieverName}</MenuItem>;
                        })}
                    </Select>
                </FormControl>
                <ResultHandler
                    association={props.association}
                    jobSk={props.jobSk}
                    taskKind={props.taskKind}
                    resultRetrieverName={selectedRetriever}
                    key={selectedRetriever}></ResultHandler>
            </div>
        </div>
    );
}

function EtlTasks(props: { association: string; tasks: Array<EtlEntry> }) {
    return (
        <div>
            <h2>Results</h2>
            {props.tasks.map(task => {
                return (
                    <ResultHandlers
                        association={props.association}
                        jobSk={task.jobPk().sk}
                        taskKind={task.getRecord().kind}
                        key={task.getRecord().sk}></ResultHandlers>
                );
            })}

            <h1>Tasks</h1>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>pk</TableCell>
                            <TableCell>sk</TableCell>
                            <TableCell>kind</TableCell>
                            <TableCell>status</TableCell>
                            <TableCell>ts</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.tasks.map(row => (
                            <TableRow
                                key={row.getRecord().sk}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell>{row.getRecord().pk}</TableCell>
                                <TableCell>{row.getRecord().sk}</TableCell>
                                <TableCell>{row.getRecord().kind}</TableCell>
                                <TableCell>
                                    <JsonPopover
                                        text={row.getRecord().status}
                                        payload={row.getRecord().logs}></JsonPopover>
                                </TableCell>
                                <TableCell>{row.jobDateTime()}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

function EtlJobs(props: {
    jobs: Array<EtlEntry>;
    fetchTasksHandler: (jobPk: string) => () => Promise<void>;
    deleteJobHandler: (jobPk: string) => () => Promise<void>;
}) {
    const [lastFetchJobSk, setLastFetchJobSk] = useState("");
    function fetchTasksHandler(jobSk: string) {
        return async () => {
            await props.fetchTasksHandler(jobSk)();
            setLastFetchJobSk(jobSk);
        };
    }
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>pk</TableCell>
                        <TableCell>sk</TableCell>
                        <TableCell>kind</TableCell>
                        <TableCell>status</TableCell>
                        <TableCell>ts</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.jobs.map(row => {
                        const r = row.getRecord();
                        return (
                            <TableRow
                                key={r.sk}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                className={r.sk == lastFetchJobSk ? "Mui-selected" : ""}>
                                <TableCell>{r.pk}</TableCell>
                                <TableCell onClick={fetchTasksHandler(r.sk)}>
                                    <div className={styles.link}>{r.sk}</div>
                                </TableCell>
                                <TableCell>{r.kind}</TableCell>
                                <TableCell>
                                    <JsonPopover text={r.status} payload={r.logs}></JsonPopover>
                                </TableCell>
                                <TableCell>{row.jobDateTime()}</TableCell>
                                <TableCell>
                                    {" "}
                                    {/* <div className={styles.form}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={props.deleteJobHandler(row.getRecord().sk)}
                  >
                    Delete
                  </Button>
                </div> */}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function Etl() {
    const [jobs, setJobs] = useState<Array<EtlEntry>>([]);
    const [tasks, setTasks] = useState<Array<EtlEntry>>([]);

    const selectedAssociation = useAppSelector(state => state.userSelection.association);

    async function fetchJobsHandler() {
        const jobs = await queryJobs(selectedAssociation);
        setJobs(jobs);
    }
    function fetchTasksHandler(jobSk: string) {
        return async () => {
            const tasks = await getJobTasks(selectedAssociation, jobSk);
            setTasks(tasks);
        };
    }
    function deleteJobHandler(jobSk: string) {
        return async () => {
            await deleteJob(selectedAssociation, jobSk);
            setJobs(jobs.filter(job => job.getRecord().sk !== jobSk));
            setTasks(tasks.filter(task => !task.getRecord().pk.includes(jobSk)));
        };
    }

    async function onJobPlacement() {
        await placeJob(selectedAssociation, "nightly", {});
        await new Promise(resolve => setTimeout(resolve, 2000));
    }

    return (
        <div>
            <div className={styles.root}>
                <EtlTasks association={selectedAssociation} tasks={tasks}></EtlTasks>

                <h1>Jobs</h1>
                <div>
                    <Button variant="contained" onClick={fetchJobsHandler}>
                        Fetch
                    </Button>
                </div>
                <EtlJobs
                    jobs={jobs}
                    fetchTasksHandler={fetchTasksHandler}
                    deleteJobHandler={deleteJobHandler}></EtlJobs>
                <div className={styles.button2}>
                    <Button variant="contained" onClick={onJobPlacement} color="warning">
                        New Job: Nightly
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Etl;
