import { ChangeEvent, useState, useEffect, useRef } from "react";
import { TextField, Button, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import styles from "./Exports.module.css";
import { useAppSelector, useAppDispatch } from "../hooks";
import { userSelectionActions } from "../store/userSelection";
import { S3InternalBlobStore, JiraTicketId, FileListEntry } from "../providers/s3";
import { TransformS3Store } from "./TransformRecords";
import PrettyFileListEntry from "./FileEntry";

import JsonDisplay from "./JsonDisplay";

// Icons
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import RefreshIcon from "@mui/icons-material/Refresh";

import { submitPwlValidateRequest } from "../providers/lambda-services";

const KEEP_OPTIONS = ["first", "last", "all", false] as const;
type KeepOption = (typeof KEEP_OPTIONS)[number];
function marshalKeepOption(keep: KeepOption): string {
    if (keep === false) {
        return "none";
    }
    return keep;
}
function parseKeepOption(keep: string): KeepOption {
    if (keep === "none") {
        return false;
    }
    return keep as KeepOption;
}

const BOOLEAN_OPTIONS = [true, false] as const;
type BooleanOption = (typeof BOOLEAN_OPTIONS)[number];
function marshalBooleanOption(bool: BooleanOption): string {
    return bool.toString();
}
function parseBooleanOption(bool: string): BooleanOption {
    return bool === "true";
}

const VALIDATOR_NAMES = ["remove_duplicates", "remove_nulls", "remove_suspect_ids", "sort"] as const;
type ValidatorName = (typeof VALIDATOR_NAMES)[number];

type Validator = {
    function: ValidatorName;
    kwargs: { key: string } & { [key: string]: any };
};
function getNewValidator(name: ValidatorName, key: string): Validator {
    const newValidator: Validator = {
        function: name,
        kwargs: { key },
    };
    switch (name) {
        case "remove_duplicates":
            newValidator.kwargs.keep = "last";
            break;
        case "sort":
            newValidator.kwargs.ascending = true;
            break;
    }
    return newValidator;
}

export class ValidateS3Store extends S3InternalBlobStore<string> {
    constructor(client: string, ticket: JiraTicketId) {
        super("validate", client, ticket);
    }
    resultFileName() {
        return "result.csv";
    }

    resultUrl() {
        return this.getS3Url(this.resultFileName());
    }
}

function Validator_remove_duplicates(props: { validator: Validator; setValidator: (validator: Validator) => void }) {
    const [key, setKey] = useState<string>(props.validator.kwargs.key);
    const [keep, setKeep] = useState<KeepOption>(props.validator.kwargs.keep);

    useEffect(() => {
        const newValidator = { ...props.validator };
        newValidator.kwargs.key = key;
        newValidator.kwargs.keep = keep;
        props.setValidator(newValidator);
    }, [key, keep]);

    return (
        <div>
            <div className={styles.textbtnrow}>
                <div className={styles.form}>
                    <TextField
                        id="key"
                        label="Key"
                        variant="outlined"
                        onChange={(e: any) => setKey(e.target.value)}
                        value={key}
                        size="small"
                    />
                </div>
                <div className={styles.form}>
                    <b>{props.validator.function}</b>
                </div>
                <div className={styles.form}>
                    <FormControl fullWidth>
                        <InputLabel id="">Keep</InputLabel>
                        <Select
                            id="remove-duplicate"
                            value={marshalKeepOption(props.validator.kwargs.keep)}
                            label="Keep"
                            size="small"
                            onChange={(event: any) => {
                                setKeep(parseKeepOption(event.target.value));
                            }}>
                            {KEEP_OPTIONS.map(keep => {
                                const keepStr = marshalKeepOption(keep);
                                return (
                                    <MenuItem value={keepStr} key={keepStr}>
                                        {keepStr}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
            </div>
        </div>
    );
}
function genValidatorBooleanOption(boolProperty: { key: string; name: string }) {
    return function (props: { validator: Validator; setValidator: (validator: Validator) => void }) {
        const [key, setKey] = useState<string>(props.validator.kwargs.key);
        const [boolOption, setBoolOption] = useState<BooleanOption>(props.validator.kwargs[boolProperty.key]);

        useEffect(() => {
            const newValidator = { ...props.validator };
            newValidator.kwargs.key = key;
            newValidator.kwargs[boolProperty.key] = boolOption;
            props.setValidator(newValidator);
        }, [key, boolOption]);

        return (
            <div>
                <div className={styles.textbtnrow}>
                    <div className={styles.form}>
                        <TextField
                            id="key"
                            label="Key"
                            variant="outlined"
                            onChange={(e: any) => setKey(e.target.value)}
                            value={key}
                            size="small"
                        />
                    </div>
                    <div className={styles.form}>
                        <b>{props.validator.function}</b>
                    </div>
                    <div className={styles.form}>
                        <FormControl fullWidth>
                            <InputLabel id="">{boolProperty.name}</InputLabel>
                            <Select
                                id="remove-duplicate"
                                value={props.validator.kwargs[boolProperty.key]}
                                label="Keep"
                                size="small"
                                onChange={(event: any) => {
                                    setBoolOption(parseBooleanOption(event.target.value));
                                }}>
                                {BOOLEAN_OPTIONS.map(b => {
                                    const keepStr = marshalBooleanOption(b);
                                    return (
                                        <MenuItem value={keepStr} key={keepStr}>
                                            {keepStr}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </div>
        );
    };
}
function Validator_simple(props: { validator: Validator; setValidator: (validator: Validator) => void }) {
    const [key, setKey] = useState<string>(props.validator.kwargs.key);

    useEffect(() => {
        const newValidator = { ...props.validator };
        newValidator.kwargs.key = key;
        props.setValidator(newValidator);
    }, [key]);

    return (
        <div>
            <div className={styles.textbtnrow}>
                <div className={styles.form}>
                    <TextField
                        id="key"
                        label="Key"
                        variant="outlined"
                        onChange={(e: any) => setKey(e.target.value)}
                        value={key}
                        size="small"
                    />
                </div>
                <div className={styles.form}>
                    <b>{props.validator.function}</b>
                </div>
            </div>
        </div>
    );
}

const VALIDATOR_LOOKUP: Record<
    ValidatorName,
    (props: { validator: Validator; setValidator: (validator: Validator) => void }) => JSX.Element
> = {
    remove_duplicates: Validator_remove_duplicates,
    remove_nulls: Validator_simple,
    remove_suspect_ids: Validator_simple,
    sort: genValidatorBooleanOption({ key: "ascending", name: "Ascending" }),
};

function Validators(props: { validators: Validator[]; setValidators: (validators: Validator[]) => void }) {
    const [newValidatorName, setNewValidatorName] = useState<ValidatorName | undefined>();
    const [newKey, setNewKey] = useState<string>("");

    function removeValidator(index: number) {
        const newValidators = [...props.validators];
        newValidators.splice(index, 1);
        props.setValidators(newValidators);
    }

    return (
        <div>
            {props.validators.map((validator, index) => {
                const Validator = VALIDATOR_LOOKUP[validator.function];
                return (
                    <div key={index} className={styles.textbtnrow}>
                        <Validator
                            validator={validator}
                            setValidator={validator => {
                                const newValidators = [...props.validators];
                                newValidators[index] = validator;
                                props.setValidators(newValidators);
                            }}
                        />
                        <div className={styles.form}>
                            <DeleteForeverIcon
                                onClick={() => {
                                    removeValidator(index);
                                }}
                            />
                        </div>
                    </div>
                );
            })}
            <div className={styles.textbtnrow}>
                <div className={styles.form}>
                    <TextField
                        id="key"
                        label="Key"
                        variant="outlined"
                        size="small"
                        onChange={(e: any) => setNewKey(e.target.value)}
                        value={newKey}
                    />
                </div>
                <div className={styles.form}>
                    <FormControl fullWidth>
                        <InputLabel id="">Validator</InputLabel>
                        <Select
                            id="validator"
                            value={newValidatorName}
                            label="Validator"
                            size="small"
                            onChange={(event: any) => {
                                setNewValidatorName(event.target.value);
                            }}>
                            {VALIDATOR_NAMES.map(name => {
                                return (
                                    <MenuItem value={name} key={name}>
                                        {name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div className={styles.form}>
                    <AddBoxIcon
                        onClick={() => {
                            if (newValidatorName && newKey !== "") {
                                const newValidator = getNewValidator(newValidatorName, newKey);
                                props.setValidators([...props.validators, newValidator]);
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

const VALIDATORS_FILE_NAME = "validators.json";
const defaultValidators: Validator[] = [
    {
        function: "remove_nulls",
        kwargs: { key: "idPassport" },
    },
    {
        function: "sort",
        kwargs: { key: "startDate", ascending: true },
    },
    {
        function: "remove_duplicates",
        kwargs: { key: "idPassport", keep: "last" },
    },
    {
        function: "remove_suspect_ids",
        kwargs: { key: "idPassport" },
    },
    {
        function: "remove_duplicates",
        kwargs: { key: "employeeNumber", keep: "all" },
    },
];

function Validate() {
    const dispatch = useAppDispatch();
    const [fileNames, setFileNames] = useState<FileListEntry<string>[]>([]);
    const [validateResult, setValidateResult] = useState<any>({});
    const [validators, setValidators] = useState<Validator[]>([]);
    const entriesFileRef = useRef<HTMLInputElement>(null);
    const ticketNumber = useAppSelector(state => state.userSelection.ticketNumber);
    function setTicketNumber(ticketNumber: string) {
        dispatch(userSelectionActions.setTicketNumber(ticketNumber));
    }

    useEffect(() => {
        getSavedConfig().then(validators => {
            if (!validators) {
                setValidators(defaultValidators);
            } else {
                setValidators(validators);
            }
        });
    }, []);

    useEffect(() => {
        if (!ticketNumber || !JiraTicketId.isValidId(ticketNumber)) {
            return;
        }
        // Update file list after 1 second of inactivity
        const timeOutId = setTimeout(() => {
            listFiles();
        }, 1000);
        return () => clearTimeout(timeOutId);
    }, [ticketNumber]);

    function getStore() {
        return new ValidateS3Store(selectedAssociation, new JiraTicketId(ticketNumber));
    }

    async function listFiles() {
        const files = await getStore().listFileNames();
        setFileNames(files);
    }

    const selectedAssociation = useAppSelector(state => state.userSelection.association);

    async function saveConfig() {
        // Upload the config file for easy refresh
        const store = getStore();
        const file = new File([JSON.stringify(validators)], VALIDATORS_FILE_NAME);
        await store.uploadFile(file, VALIDATORS_FILE_NAME);
    }
    async function getSavedConfig(): Promise<Validator[] | undefined> {
        const store = getStore();
        const fileExists = await store.fileExists(VALIDATORS_FILE_NAME);
        if (!fileExists) {
            return undefined;
        }
        const content = await store.getFileContents(VALIDATORS_FILE_NAME);
        return JSON.parse(content) as Validator[];
    }
    async function loadConfig() {
        const savedValidators = await getSavedConfig();
        if (!savedValidators) {
            alert("No validators.json found");
            return;
        }
        setValidators(savedValidators);
    }

    async function setEntriesInputFromTransformStore() {
        const transformStore = new TransformS3Store(selectedAssociation, new JiraTicketId(ticketNumber));
        const transformResultFileName = "entriesTransform_dynamic.csv";
        const fileExists = await transformStore.fileExists(transformResultFileName);
        if (!fileExists) {
            alert("No results.csv found from the Transform tool");
            return;
        }
        const unifyResultKey = transformStore.anyFileKey(transformResultFileName);
        await getStore().copyFile(unifyResultKey, "entries.csv");
        await listFiles();
    }

    function handleFileChange(targetFile: string) {
        return async function (e: ChangeEvent<HTMLInputElement>) {
            if (e.target.files) {
                const selectedFile = e.target.files[0];
                await getStore().uploadFile(selectedFile, targetFile);
                await listFiles();
            }
        };
    }

    async function handleValidateSubmit() {
        const store = getStore();
        const entriesFileUrl = await store.getS3UrlIfExists("entries.csv");
        const dirUrl = store.getS3DirUrl();
        const payload = {
            file_url: entriesFileUrl,
            result_base_path_url: dirUrl,
            validators,
        };

        const filesToKeep = ["entries.csv"];
        await Promise.all(
            fileNames.map(async f => {
                if (filesToKeep.includes(f.fileName)) {
                    return;
                }
                return removeFile(f.fileName);
            }),
        );

        const result = await submitPwlValidateRequest(payload);
        console.log(result);
        setValidateResult(result);
        await saveConfig();
        await listFiles();
    }
    async function removeFile(fileName: string) {
        await getStore().deleteFile(fileName);
        setFileNames(fileNames.filter(f => f.fileName !== fileName));
    }
    async function downloadFile(fileName: string) {
        await getStore().downloadFile(fileName);
    }

    return (
        <div className={styles.root}>
            <div className={styles.textbtnrow}>
                <div className={styles.form}>
                    <TextField
                        id="ticketNumber"
                        label="Ticket Number"
                        variant="outlined"
                        onChange={(e: any) => setTicketNumber(e.target.value)}
                        value={ticketNumber}
                    />
                </div>
            </div>
            <div className={styles.files}>
                {fileNames.map(f => {
                    return (
                        <div className={styles.form} key={f.fileName}>
                            <PrettyFileListEntry file={f} />{" "}
                            <CloudDownloadIcon onClick={() => downloadFile(f.fileName)} />{" "}
                            <DeleteForeverIcon onClick={() => removeFile(f.fileName)} />
                            {f.fileName === VALIDATORS_FILE_NAME && <RefreshIcon onClick={loadConfig} />}
                        </div>
                    );
                })}
            </div>
            <div>
                <div className={styles.textbtnrow}>
                    <div className={styles.form}>Entries</div>
                    <div className={styles.form}>
                        <Button variant="contained" size="small" onClick={setEntriesInputFromTransformStore}>
                            From Transform
                        </Button>
                    </div>
                    <div className={styles.form}>or</div>
                    <div className={styles.form}>
                        <input
                            ref={entriesFileRef}
                            type="file"
                            onClick={() => {
                                if (entriesFileRef.current) {
                                    entriesFileRef.current.value = "";
                                }
                            }}
                            onInput={handleFileChange("entries.csv")}
                        />{" "}
                    </div>
                </div>
            </div>
            <div>
                <div className={styles.textbtnrow}>
                    <Validators validators={validators} setValidators={setValidators} />
                </div>
            </div>
            <div className={styles.textbtnrow}>
                <div className={styles.form}>
                    <Button variant="contained" onClick={handleValidateSubmit}>
                        Validate
                    </Button>
                </div>
            </div>
            <div className={styles.form}>
                <JsonDisplay data={validateResult} />
            </div>
        </div>
    );
}

export default Validate;
