const idPoolMap = {
    staging: "eu-west-1:53250023-3c94-45fc-adaf-058de67dc20f",
    prod: "eu-west-1:945ed7a5-fe04-4186-bf93-5db49f17af01",
    // Using prod user pool for easy testing
    local: "eu-west-1:945ed7a5-fe04-4186-bf93-5db49f17af01",
};
const userpoolIdMap = {
    staging: "eu-west-1_XrdhhKGBb",
    prod: "eu-west-1_E3ghwkfDI",
    local: "eu-west-1_E3ghwkfDI",
};

const webClientMap = {
    staging: "17vbi6pq6ojq66jto97qa43h78",
    prod: "3req3caub15rmapbl5dh7r0s53",
    local: "3req3caub15rmapbl5dh7r0s53",
};

function endpoint(apiEnv, sub, dataEnvForLocal) {
    if (apiEnv === "local") {
        switch (sub) {
            case "core":
                return `http://localhost:3001/${dataEnvForLocal}`;
            case "etl":
                return `http://localhost:3002/${dataEnvForLocal}`;
            case "pwl":
                return `http://localhost:5000/${dataEnvForLocal}`;
            default:
        }
    }
    return `https://${apiEnv}.peopleflow.co.za/${sub}`;
}

export const getAwsConfig = (apiEnv, dataEnvForLocal) => {
    return {
        aws_project_region: "af-south-1",
        aws_cognito_region: "eu-west-1",
        aws_cognito_identity_pool_id: idPoolMap[apiEnv],
        aws_user_pools_id: userpoolIdMap[apiEnv],
        aws_user_pools_web_client_id: webClientMap[apiEnv],
        oauth: {},
        aws_user_files_s3_bucket: `peopleflow-service-${apiEnv}-general-storage`,
        aws_user_files_s3_bucket_region: "af-south-1",

        API: {
            endpoints: [
                // Need to get the custom/alias endpoints working on staging
                {
                    name: "people_flow_import_export",
                    endpoint: endpoint(apiEnv, "e"),
                    region: "af-south-1",
                },
                {
                    name: "people_flow_data_sync",
                    endpoint: endpoint(apiEnv, "s"),
                    region: "af-south-1",
                },
                {
                    name: "people_flow_device_state",
                    endpoint: endpoint(apiEnv, "d"),
                    region: "af-south-1",
                },
                {
                    name: "people_flow_user",
                    endpoint: endpoint(apiEnv, "u"),
                    region: "af-south-1",
                },
                {
                    name: "people_flow_billing",
                    endpoint: endpoint(apiEnv, "b"),
                    region: "af-south-1",
                },
                {
                    name: "people_flow_core",
                    endpoint: endpoint(apiEnv, "core", dataEnvForLocal),
                    region: "af-south-1",
                },
                {
                    name: "people_flow_etl",
                    endpoint: endpoint(apiEnv, "etl", dataEnvForLocal),
                    region: "af-south-1",
                },
                {
                    name: "people_flow_pwl",
                    endpoint: endpoint(apiEnv, "pwl", dataEnvForLocal),
                    region: "af-south-1",
                },
                {
                    name: "verifyIDAuth",
                    endpoint: "https://www.verifyid.co.za/webservice/authenticate",
                },
                {
                    name: "checkCredits",
                    endpoint: "https://www.verifyid.co.za/webservice/my_credits",
                },
                {
                    name: "verifyID",
                    endpoint: "https://www.verifyid.co.za/webservice/said_verification",
                },
            ],
        },
    };
};
