import { Auth, API } from "aws-amplify";
import { LS_EmployeeProfile } from "../models/existing/lsModels";
import { EtlEntry, RecordSchema } from "../models/Record";

export async function httpGet(apiName: string, path: string): Promise<any> {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    let init = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token,
        },
        response: true,
    };

    return API.get(apiName, path, init);
}

export async function httpDelete(apiName: string, path: string): Promise<any> {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    let init = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token,
        },
        response: true,
    };

    return API.del(apiName, path, init);
}

export async function httpPost(apiName: string, path: string, body: any): Promise<any> {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    let init = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token,
        },
        response: true,
        body: { ...body },
    };
    return API.post(apiName, path, init).catch(error => console.log(error.response.data));
}

export async function getAssociations(): Promise<string[]> {
    return httpGet("people_flow_core", `/associations/`).then(httpresult => {
        console.log(httpresult);
        const { status, data } = httpresult;
        if (status !== 200) {
            throw { code: "UnknownError" };
        }
        return data;
    });
}

export async function queryProfile(
    association: string,
    idPassport: string,
): Promise<Record<string, LS_EmployeeProfile>> {
    return httpGet("people_flow_core", `/employees/association/${association}/profile/${idPassport}`).then(
        httpresult => {
            console.log(httpresult);
            const { status, data } = httpresult;
            if (status !== 200) {
                throw { code: "UnknownError" };
            }
            return data;
        },
    );
}

export async function docCheck(association: string, idPassport: string, dockKey: string | undefined): Promise<boolean> {
    if (!dockKey) {
        return Promise.resolve(false);
    }
    return httpGet(
        "people_flow_core",
        `/employees/association/${association}/profile/${idPassport}/docCheck/${dockKey}`,
    ).then(httpresult => {
        console.log(httpresult);
        const { status, data } = httpresult;
        if (status !== 200) {
            throw { code: "UnknownError" };
        }
        return data;
    });
}

export async function queryProfileIndexes(association: string): Promise<Array<any>> {
    return httpGet("people_flow_core", `/employees/association/${association}/profileIndex`).then(httpresult => {
        console.log(httpresult);
        const { status, data } = httpresult;
        if (status !== 200) {
            throw { code: "UnknownError" };
        }
        return data;
    });
}

export type MetricType = "employee" | "candidate" | "idverification" | "bankverification" | string;
export type BillTrendEntry = { unixMs: number; price: number; quantity?: number };
export type BillTrends = Record<MetricType, BillTrendEntry[]>;
export type ProfileBill = { price: number; quantity: number };
export type ProfileTrendEntry = {
    unixMs: number;
    employees: ProfileBill | undefined;
    candidates: ProfileBill | undefined;
};

export async function queryProfilesBilling(association: string): Promise<ProfileTrendEntry[]> {
    return httpGet("people_flow_core", `/billing/association/${association}/profiles`).then(httpresult => {
        console.log(httpresult);
        const { status, data } = httpresult;
        if (status !== 200) {
            throw { code: "UnknownError" };
        }
        return data;
    });
}

export async function queryVerificationBilling(association: string): Promise<BillTrends> {
    return httpGet("people_flow_core", `/billing/association/${association}/verification`).then(httpresult => {
        console.log(httpresult);
        const { status, data } = httpresult;
        if (status !== 200) {
            throw { code: "UnknownError" };
        }
        return data;
    });
}

export async function queryJobs(association: string): Promise<EtlEntry[]> {
    return httpGet("people_flow_etl", `/api/association/${association}/jobs`).then(httpresult => {
        console.log(httpresult);
        const { status, data } = httpresult;
        if (status !== 200) {
            throw { code: "UnknownError" };
        }
        const parsed = data.map((record: any) => {
            return new EtlEntry(RecordSchema.parse(record));
        });
        parsed.sort((a: EtlEntry, b: EtlEntry) => {
            console.log(a.jobDateTime(), b.jobDateTime());
            return b.jobDateTime().localeCompare(a.jobDateTime());
        });
        return parsed;
    });
}

export async function getJobTasks(association: string, jobSk: string): Promise<EtlEntry[]> {
    return httpGet("people_flow_etl", `/api/association/${association}/job/${encodeURIComponent(jobSk)}`).then(
        httpresult => {
            console.log(httpresult);
            const { status, data } = httpresult;
            if (status !== 200) {
                throw { code: "UnknownError" };
            }
            const parsed: EtlEntry[] = data.tasks.map((record: any) => {
                return new EtlEntry(RecordSchema.parse(record));
            });
            return parsed;
        },
    );
}

export async function deleteJob(association: string, jobSk: string): Promise<void> {
    return httpDelete("people_flow_etl", `/api/association/${association}/job/${encodeURIComponent(jobSk)}`).then(
        httpresult => {
            console.log(httpresult);
            const { status, data } = httpresult;
            if (status !== 200) {
                throw { code: "UnknownError" };
            }
        },
    );
}

export async function getTaskResultRetrievers(association: string, jobSk: string, taskKind: string): Promise<string[]> {
    return httpGet(
        "people_flow_etl",
        `/api/association/${association}/results/${encodeURIComponent(jobSk)}?taskKind=${encodeURIComponent(taskKind)}`,
    ).then(httpresult => {
        console.log(httpresult);
        const { status, data } = httpresult;
        if (status !== 200) {
            throw { code: "UnknownError" };
        }
        return data.handlers;
    });
}

export async function getTaskResults(
    association: string,
    jobSk: string,
    taskKind: string,
    handlerName: string,
    params: any,
): Promise<{ payload: string; filetype: string; show: boolean }> {
    return httpPost(
        "people_flow_etl",
        `/api/association/${association}/results/${encodeURIComponent(jobSk)}?taskKind=${encodeURIComponent(
            taskKind,
        )}&handler=${encodeURIComponent(handlerName)}`,
        params,
    ).then(httpresult => {
        console.log(httpresult);
        const { status, data } = httpresult;
        if (status !== 200) {
            throw { code: "UnknownError" };
        }
        return data;
    });
}

export async function placeJob(
    association: string,
    jobName: string,
    params: any,
): Promise<{ payload: string; filetype: string; show: boolean }> {
    return httpPost(
        "people_flow_etl",
        `/api/association/${association}/jobs/${encodeURIComponent(jobName)}`,
        params,
    ).then(httpresult => {
        console.log(httpresult);
        const { status, data } = httpresult;
        if (status !== 200) {
            throw { code: "UnknownError" };
        }
        return data;
    });
}

export async function submitExportRequest(
    association: string,
    emails: string[],
    exportTemplateParams: any,
    selectedEmploymentStatus?: string,
    selectedProfiles?: string[],
): Promise<any> {
    const profilesExport = { exportTemplateParams };
    const params = {
        selectedEmployer: association,
        selectedProfiles,
        selectedEmploymentStatus,
        emails,
        profilesExport,
    };
    console.log({ params });
    return httpPost("people_flow_import_export", `/data-export/profile-data-template`, params).then(httpresult => {
        console.log(httpresult);
        const { status, data } = httpresult;
        if (status !== 200) {
            throw { code: "UnknownError" };
        }
        return data;
    });
}

export async function submitPlanBulkActionRequest(payload: any): Promise<any> {
    console.log("payload: ", JSON.stringify(payload));
    return httpPost("people_flow_import_export", `/data-import/plan-profile-import`, payload).then(httpresult => {
        console.log(httpresult);
        const { status, data } = httpresult;
        if (status !== 200) {
            throw { code: "UnknownError" };
        }
        return data;
    });
}

export type ProfileExportFilter = {
    targetProfilePks?: string[];
    employmentStatus?: string;
    trashStatus?: string;
    cohort?: string;
};

export async function submitSlsProfileExportRequest(
    association: string,
    exportTemplateParams: any,
    profileFilter: ProfileExportFilter,
    resultDir: string,
    format?: string,
): Promise<any> {
    const params = {
        association,
        format,
        exportTemplateParams,
        profileFilter,
        result_base_path_url: resultDir,
    };
    console.log({ params });
    return httpPost("people_flow_core", `/internal/export/profiles`, params)
        .then(httpresult => {
            console.log(httpresult);
            const { status, data } = httpresult;
            if (status !== 200) {
                console.error(data);
                return;
            }
            console.log(data);
        })
        .catch(err => {
            console.error(err);
        });
}
export async function submitSlsEntriesTransformRequest(
    exportTemplateParams: any,
    entriesFilepath: any,
    resultDir: string,
    format?: string,
): Promise<any> {
    const params = {
        format,
        exportTemplateParams,
        entriesFilepath,
        result_base_path_url: resultDir,
    };
    console.log({ params });
    return httpPost("people_flow_core", `/internal/transform/entries`, params)
        .then(httpresult => {
            console.log(httpresult);
            const { status, data } = httpresult;
            if (status !== 200) {
                console.error(data);
                return;
            }
            console.log(data);
        })
        .catch(err => {
            console.error(err);
        });
}

export async function submitPwlUnifyRequest(payload: any): Promise<any> {
    console.log({ payload });
    return httpPost("people_flow_pwl", `/unify`, payload)
        .then(httpresult => {
            console.log(httpresult);
            const { status, data } = httpresult;
            if (status !== 200) {
                console.error(data);
                return;
            }
        })
        .catch(err => {
            console.error(err);
        });
}

export async function submitPwlValidateRequest(payload: any): Promise<any> {
    console.log({ payload });
    return httpPost("people_flow_pwl", `/validate`, payload)
        .then(httpresult => {
            console.log(httpresult);
            const { status, data } = httpresult;
            if (status !== 200) {
                console.error(data);
                return;
            }
            return data;
        })
        .catch(err => {
            console.error(err);
        });
}
