import { Storage } from "aws-amplify";
import { awsConfig } from "../index"; // Is there a better way to get the bucket name?

export class JiraTicketId {
    private static regex = /^PF-\d{1,5}$/;
    constructor(private ticket: string) {
        if (!JiraTicketId.isValidId(ticket)) {
            const errorMessage = `Invalid ticket name: ${ticket}. Must conform: ${JiraTicketId.regex}`;
            alert(errorMessage);
            throw new Error(errorMessage);
        }
    }
    static isValidId(value: string) {
        return JiraTicketId.regex.test(value);
    }
    id() {
        return this.ticket;
    }
}

function downloadBlob(blob: Blob, filename: string) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
        setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener("click", clickHandler);
        }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
}

export type StoreType = "export";

export type Store<Files extends keyof string[]> = {
    type: StoreType;
    files: Files;
};

export interface FileListEntry<FileName extends string> {
    fileName: FileName;
    lastModified?: Date;
}

export class S3InternalBlobStore<FileName extends string> {
    constructor(
        private storeName: string,
        private client: string,
        private ticket: JiraTicketId,
        private subDir: string = "",
    ) {}
    private pathPrefix() {
        const path = `Companies/${this.client}/internal/${this.storeName}/${this.ticket.id()}`;
        if (this.subDir && this.subDir.length > 0) {
            return `${path}/${this.subDir}`;
        }
        return path;
    }

    private fullyQualifiedFilename(filename: string) {
        return `internal_${this.client}_${this.storeName}_${this.ticket.id()}_${filename}`;
    }

    private bucketName() {
        return awsConfig.aws_user_files_s3_bucket;
    }

    anyFileKey(filename: string) {
        return this.pathPrefix() + "/" + filename;
    }
    fileKey(filename: FileName) {
        return this.anyFileKey(filename);
    }

    async uploadFile(file: File, filename: FileName) {
        const path = this.fileKey(filename);
        return await Storage.put(path, file, {
            contentType: file.type,
        });
    }
    async copyFile(srcKey: string, destFileName: FileName) {
        const path = this.pathPrefix() + "/" + destFileName;
        const source = { key: srcKey };
        const dest = { key: path };
        return await Storage.copy(source, dest);
    }
    async getFileContents(filename: FileName) {
        const path = this.fileKey(filename);
        const doc = await Storage.get(path, { download: true, cacheControl: "no-cache" });
        console.log(doc);
        if (!doc.Body) {
            console.error(`No body for ${filename}`);
        }
        const blob = doc.Body as Blob;
        return blob.text();
    }
    async downloadFile(filename: FileName) {
        const path = this.fileKey(filename);
        const docUrl = await Storage.get(path, { download: true, cacheControl: "no-cache" });
        console.log(docUrl);
        downloadBlob(docUrl.Body as Blob, this.fullyQualifiedFilename(filename));
    }
    async deleteFile(filename: FileName) {
        const path = this.fileKey(filename);
        const result = await Storage.remove(path);
        console.log(result);
        return result;
    }
    async listFiles(filename: FileName) {
        const path = this.fileKey(filename);
        return await Storage.list(path, { pageSize: 50 });
    }
    async listFileNames(filename: FileName | undefined = undefined): Promise<FileListEntry<FileName>[]> {
        const path = this.pathPrefix() + (filename ? "/" + filename : "/");
        const list = await Storage.list(path, { pageSize: 50 });
        return (
            list &&
            list.results &&
            list.results.map(result => {
                const key = result.key as string;
                const fileName = key.replace(this.pathPrefix() + "/", "") as FileName;
                return { fileName, lastModified: result.lastModified };
            })
        );
    }
    async fileExists(filename: FileName) {
        const path = this.fileKey(filename);
        const list = await Storage.list(path);
        return list && list.results && list.results.length === 1;
    }

    getS3DirUrl() {
        return `s3://${this.bucketName()}/public/${this.pathPrefix()}`;
    }
    protected getAnyS3Url(filename: string) {
        return `${this.getS3DirUrl()}/${filename}`;
    }
    getS3Url(filename: FileName) {
        return this.getAnyS3Url(filename);
    }
    async getS3UrlIfExists(filename: FileName) {
        if (await this.fileExists(filename)) {
            return this.getS3Url(filename);
        }
        return undefined;
    }
}
