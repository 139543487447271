import { ChangeEvent, useState, useEffect, useRef } from "react";
import { TextField, Button, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import styles from "./Exports.module.css";
import { useAppSelector, useAppDispatch } from "../hooks";
import { userSelectionActions } from "../store/userSelection";
import { S3InternalBlobStore, JiraTicketId, FileListEntry } from "../providers/s3";
import { ValidateS3Store } from "./Validate";
import { ImportAction, BulkActionConfig, BulkActionConfigBuilder, IMPORT_ACTIONS } from "../models/BulkAction";
import PrettyFileListEntry from "./FileEntry";

import JsonDisplay from "./JsonDisplay";

// Icons
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import RefreshIcon from "@mui/icons-material/Refresh";

import { submitPlanBulkActionRequest } from "../providers/lambda-services";

class BulkActionS3Store extends S3InternalBlobStore<string> {
    constructor(client: string, ticket: JiraTicketId, action: ImportAction) {
        super("bulkAction", client, ticket, action);
    }
    resultFileName() {
        return "result.csv";
    }

    resultUrl() {
        return this.getS3Url(this.resultFileName());
    }
}

const BULKACTIONCONFIG_FILE_NAME = "bulkActionConfig.json";

function PlanBulkAction() {
    const dispatch = useAppDispatch();
    const [fileNames, setFileNames] = useState<FileListEntry<string>[]>([]);
    const [planResult, setPlanResult] = useState<any>({});
    const [selectedAction, setSelectedAction] = useState<ImportAction>("NEW_PROFILE");
    const [isLoading, setIsLoading] = useState(false);
    const [bulkActionConfig, setBulkActionConfig] = useState<BulkActionConfig>(
        new BulkActionConfigBuilder().withAction(selectedAction).build(),
    );
    const entriesFileRef = useRef<HTMLInputElement>(null);
    const ticketNumber = useAppSelector(state => state.userSelection.ticketNumber);
    function setTicketNumber(ticketNumber: string) {
        dispatch(userSelectionActions.setTicketNumber(ticketNumber));
    }

    useEffect(() => {
        getSavedConfig().then(bulkAction => {
            if (bulkAction) {
                setBulkActionConfig(bulkAction);
            }
        });
    }, []);

    useEffect(() => {
        if (!ticketNumber || !JiraTicketId.isValidId(ticketNumber)) {
            return;
        }
        // Update file list after 1 second of inactivity
        const timeOutId = setTimeout(() => {
            listFiles();
        }, 1000);
        return () => clearTimeout(timeOutId);
    }, [ticketNumber, selectedAction]);

    useEffect(() => {
        setBulkActionConfig(new BulkActionConfigBuilder(bulkActionConfig).withAction(selectedAction).build());
    }, [selectedAction]);

    function getStore() {
        const action = bulkActionConfig.action;
        return new BulkActionS3Store(selectedAssociation, new JiraTicketId(ticketNumber), action);
    }

    async function listFiles() {
        const files = await getStore().listFileNames();
        setFileNames(files);
    }

    const selectedAssociation = useAppSelector(state => state.userSelection.association);

    async function saveConfig() {
        // Upload the config file for easy refresh
        const store = getStore();
        const file = new File([JSON.stringify(bulkActionConfig)], BULKACTIONCONFIG_FILE_NAME);
        await store.uploadFile(file, BULKACTIONCONFIG_FILE_NAME);
    }
    async function getSavedConfig(): Promise<BulkActionConfig | undefined> {
        const store = getStore();
        const fileExists = await store.fileExists(BULKACTIONCONFIG_FILE_NAME);
        if (!fileExists) {
            return undefined;
        }
        const content = await store.getFileContents(BULKACTIONCONFIG_FILE_NAME);
        return JSON.parse(content) as BulkActionConfig;
    }
    async function loadConfig() {
        const savedConfig = await getSavedConfig();
        if (!savedConfig) {
            alert("No validators.json found");
            return;
        }
        setBulkActionConfig(savedConfig);
    }

    async function setEntriesInputFromValidateStore() {
        const validateStore = new ValidateS3Store(selectedAssociation, new JiraTicketId(ticketNumber));
        const validateResultFileName = "result.csv";
        const fileExists = await validateStore.fileExists(validateResultFileName);
        if (!fileExists) {
            alert("No results.csv found from the Validate tool");
            return;
        }
        const resultKey = validateStore.anyFileKey(validateResultFileName);
        await getStore().copyFile(resultKey, "entries.csv");
        await listFiles();
    }

    function handleFileChange(targetFile: string) {
        return async function (e: ChangeEvent<HTMLInputElement>) {
            if (e.target.files) {
                const selectedFile = e.target.files[0];
                await getStore().uploadFile(selectedFile, targetFile);
                await listFiles();
            }
        };
    }

    async function handleSubmit() {
        const store = getStore();
        const dirUrl = store.getS3DirUrl();
        const payload = {
            selectedEmployer: selectedAssociation,
            inputPathPrefix: dirUrl,
            importFileName: "entries.csv",
            bulkActionConfig,
        };

        const filesToKeep = ["entries.csv"];
        await Promise.all(
            fileNames.map(async f => {
                if (filesToKeep.includes(f.fileName)) {
                    return;
                }
                return removeFile(f.fileName);
            }),
        );

        setIsLoading(true);
        const result = await submitPlanBulkActionRequest(payload);
        console.log(result);
        const { report } = result;
        delete report.config;
        setPlanResult(report);
        await saveConfig();
        await listFiles();
        setIsLoading(false);
    }
    async function removeFile(fileName: string) {
        await getStore().deleteFile(fileName);
        setFileNames(fileNames.filter(f => f.fileName !== fileName));
    }
    async function downloadFile(fileName: string) {
        await getStore().downloadFile(fileName);
    }

    return (
        <div className={styles.root}>
            <div className={styles.textbtnrow}>
                <div className={styles.form}>
                    <TextField
                        id="ticketNumber"
                        label="Ticket Number"
                        variant="outlined"
                        onChange={(e: any) => setTicketNumber(e.target.value)}
                        value={ticketNumber}
                    />
                </div>
            </div>
            <div className={styles.form}>
                <FormControl>
                    <InputLabel id="">Action</InputLabel>
                    <Select
                        id="tool-select"
                        value={selectedAction}
                        label="Action"
                        onChange={(event: any) => {
                            setSelectedAction(event.target.value);
                        }}>
                        {Object.values(IMPORT_ACTIONS).map(action => {
                            return (
                                <MenuItem value={action} key={action}>
                                    {action}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>
            <div>
                <div className={styles.textbtnrow}>
                    <div className={styles.form}>Entries</div>
                    <div className={styles.form}>
                        <Button variant="contained" size="small" onClick={setEntriesInputFromValidateStore}>
                            From Validate
                        </Button>
                    </div>
                    <div className={styles.form}>or</div>
                    <div className={styles.form}>
                        <input
                            ref={entriesFileRef}
                            type="file"
                            onClick={() => {
                                if (entriesFileRef.current) {
                                    entriesFileRef.current.value = "";
                                }
                            }}
                            onInput={handleFileChange("entries.csv")}
                        />{" "}
                    </div>
                </div>
            </div>
            <div className={styles.files}>
                {fileNames.map(f => {
                    return (
                        <div className={styles.form} key={f.fileName}>
                            <PrettyFileListEntry file={f} />{" "}
                            <CloudDownloadIcon onClick={() => downloadFile(f.fileName)} />{" "}
                            <DeleteForeverIcon onClick={() => removeFile(f.fileName)} />
                            {f.fileName === BULKACTIONCONFIG_FILE_NAME && <RefreshIcon onClick={loadConfig} />}
                        </div>
                    );
                })}
            </div>
            <div>
                <div className={styles.textbtnrow}>
                    <JsonDisplay data={bulkActionConfig} />
                </div>
            </div>
            <div className={styles.textbtnrow}>
                <div className={styles.form}>
                    <Button variant="contained" onClick={handleSubmit}>
                        Plan
                    </Button>
                </div>
            </div>
            <div className={styles.form}>
                {isLoading && <div>Loading...</div>}
                {!isLoading && <JsonDisplay data={planResult} />}
            </div>
        </div>
    );
}

export default PlanBulkAction;
