import { useState, useEffect } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { useAppSelector, useAppDispatch } from "../hooks";
import { userSelectionActions } from "../store/userSelection";
import { thunkFetchProfileIndexes, thunkFetchProfile } from "../store/profiles";

import styles from "./ProfileIndex.module.css";

function ProfileIndex() {
  const dispatch = useAppDispatch();

  const selectedAssociation = useAppSelector(
    (state) => state.userSelection.association
  );

  const profileIndexes = useAppSelector((state) => state.profiles.index);

  async function fetchProfilesHandler() {
    dispatch(thunkFetchProfileIndexes(selectedAssociation));
  }

  return (
    <div className={styles.root}>
      <div className={styles.form}>
        <Button variant="contained" onClick={fetchProfilesHandler}>
          Fetch
        </Button>
      </div>
      <ProfileIndexTable profileIndexes={profileIndexes} />
    </div>
  );
}

function ProfileIndexTable(props: { profileIndexes: Array<any> }) {
  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState<Array<any>>([]);
  const [indexList, setIndexList] = useState<Array<Record<string, string>>>([]);

  const selectedAssociation = useAppSelector(
    (state) => state.userSelection.association
  );

  useEffect(() => {
    setIndexList(props.profileIndexes);
  }, [props.profileIndexes]);

  function onSearchInput(event: any) {
    const keyword = event.target.value;
    setSearchTerm(keyword);
    if (keyword === "") {
      setIndexList(props.profileIndexes);
      return;
    }
    const filtered = props.profileIndexes.filter((index) => {
      return index.idPassport?.includes(keyword);
    });
    setIndexList(filtered);
  }

  function onProfileClickClosure(idPassport: string) {
    return function (event: any) {
      dispatch(thunkFetchProfile(selectedAssociation, idPassport));
      dispatch(userSelectionActions.setIdPassport(idPassport));
      dispatch(userSelectionActions.setTool("profile"));
    };
  }

  return (
    <div>
      <div className={styles.search}>
        <TextField
          id="searchTerm"
          label="Filter idPassport"
          variant="outlined"
          onChange={onSearchInput}
          value={searchTerm}
        />
        <h3>{indexList.length}</h3>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>idPassport</TableCell>
              <TableCell>cohorts</TableCell>
              <TableCell>trashStatus</TableCell>
              <TableCell>pfid</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {indexList.map((row) => (
              <TableRow
                key={row.idPassport}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <div className={styles.idCell}>
                    <div
                      className={styles.idSearch}
                      onClick={onProfileClickClosure(row.idPassport)}
                    ></div>
                    <div>{row.idPassport}</div>
                  </div>
                </TableCell>
                <TableCell>{row.cohorts}</TableCell>
                <TableCell>{row.trashStatus}</TableCell>
                <TableCell>{row.pfid}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default ProfileIndex;
