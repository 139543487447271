import { useEffect, useState } from "react";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";

import ClientSelector from "./ClientSelector";

import { useAppSelector, useAppDispatch } from "../hooks";
import { userSelectionActions, TOOL } from "../store/userSelection";
import { profilesActions } from "../store/profiles";
import { getAssociations } from "../providers/lambda-services";

import styles from "./AppBar.module.css";

interface AppBarProps {
    signOut?: any;
}

function AppBar(props: AppBarProps) {
    const [associations, setAssociations] = useState<Array<string>>([]);
    const selectedTool = useAppSelector(state => state.userSelection.tool);
    const selectedAssociation = useAppSelector(state => state.userSelection.association);

    async function getAssociationsList() {
        const associations = await getAssociations();
        setAssociations(associations);
    }

    useEffect(() => {
        getAssociationsList();
    }, []);

    function onAssociationChange(event: any) {
        dispatch(userSelectionActions.setAssociation(event.target.value));
        dispatch(userSelectionActions.setIdPassport(""));
        dispatch(profilesActions.setIndex([]));
        dispatch(profilesActions.setLastFetchedProfile({}));
        dispatch(userSelectionActions.setTool("profileIndex"));
    }

    const dispatch = useAppDispatch();
    return (
        <div className={styles.root}>
            <div className={styles.inputs}>
                <div className={styles.button}>
                    <ClientSelector
                        association={selectedAssociation}
                        associationList={associations}
                        onChange={onAssociationChange}
                    />
                </div>
                <div className={styles.button}>
                    <FormControl fullWidth>
                        <InputLabel id="">Tool</InputLabel>
                        <Select
                            id="tool-select"
                            value={selectedTool}
                            label="Tools"
                            onChange={(event: any) => dispatch(userSelectionActions.setTool(event.target.value))}>
                            {Object.values(TOOL).map(tool => {
                                return (
                                    <MenuItem value={tool} key={tool}>
                                        {tool}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
            </div>

            <Button variant="outlined" onClick={props.signOut}>
                Sign Out
            </Button>
        </div>
    );
}

export default AppBar;
