import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

function ClientSelector(props: {
    association: string;
    associationList: Array<string>;
    onChange: ((event: any) => void) | undefined;
}) {
    return (
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Client</InputLabel>
            <Select
                labelId="client-select-label"
                label="Client"
                id="client-select"
                value={props.association}
                onChange={props.onChange}>
                {props.associationList.map(association => {
                    return (
                        <MenuItem value={association} key={association}>
                            {association}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}

export default ClientSelector;
