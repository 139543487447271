import styles from "./JsonDisplay.module.css";

function JsonDisplay(props: { data: any }) {
  return (
    <pre className={styles.json} id="json">
      {JSON.stringify(props.data, null, 2)}
    </pre>
  );
}

export default JsonDisplay;
