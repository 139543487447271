import { ChangeEvent, useState, useEffect, useRef } from "react";
import { TextField, Button, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import styles from "./Exports.module.css";
import { useAppSelector, useAppDispatch } from "../hooks";
import { userSelectionActions } from "../store/userSelection";
import { S3InternalBlobStore, JiraTicketId, FileListEntry } from "../providers/s3";
import { getCurrentUserEmail } from "../providers/auth";
import PrettyFileListEntry from "./FileEntry";

// Icons
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

import { ProfileExportFilter, submitExportRequest, submitSlsProfileExportRequest } from "../providers/lambda-services";

type EmploymentStatus = "ALL" | "EMPLOYEE" | "CANDIDATE";

const exportFiles = ["rows.csv", "mappings.csv", "entries.csv", "entries.json"] as const;
export type ExportFile = (typeof exportFiles)[number];

export class ExportS3Store extends S3InternalBlobStore<ExportFile> {
    constructor(client: string, ticket: JiraTicketId) {
        super("export", client, ticket);
    }
}

function Exports() {
    const dispatch = useAppDispatch();
    const [fileNames, setFileNames] = useState<FileListEntry<ExportFile>[]>([]);
    const [format, setFormat] = useState<string>("csv");
    const employmentStatuses: EmploymentStatus[] = ["ALL", "EMPLOYEE", "CANDIDATE"];
    const [employmentStatus, setEmploymentStatus] = useState<EmploymentStatus>("ALL");
    const [email, setEmail] = useState<string>("");
    const [idPassports, setIdPassports] = useState<string[]>([]);
    const [someMessage, setSomeMessage] = useState<string>();
    const [someMessageTimeout, setSomeMessageTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined);
    const rowsFileRef = useRef<HTMLInputElement>(null);
    const mappingsFileRef = useRef<HTMLInputElement>(null);
    const entriesFileRef = useRef<HTMLInputElement>(null);
    const ticketNumber = useAppSelector(state => state.userSelection.ticketNumber);
    function setTicketNumber(ticketNumber: string) {
        dispatch(userSelectionActions.setTicketNumber(ticketNumber));
    }

    useEffect(() => {
        async function fetchEmail() {
            const email = await getCurrentUserEmail();
            setEmail(email);
        }
        fetchEmail();
    }, []);

    useEffect(() => {
        if (!ticketNumber || !JiraTicketId.isValidId(ticketNumber)) {
            return;
        }
        // Update file list after 1 second of inactivity
        const timeOutId = setTimeout(() => {
            listFiles();
        }, 1000);
        return () => clearTimeout(timeOutId);
    }, [ticketNumber]);

    function getStore() {
        return new ExportS3Store(selectedAssociation, new JiraTicketId(ticketNumber));
    }

    async function listFiles() {
        const exportS3Store = getStore();
        const files = await exportS3Store.listFileNames();
        console.log(files);
        setFileNames(files);
    }

    function flashMessage(message: string) {
        clearTimeout(someMessageTimeout);
        setSomeMessage(message);
        const timeout = setTimeout(() => {
            setSomeMessage(undefined);
        }, 5000);
        setSomeMessageTimeoutId(timeout);
    }

    const selectedAssociation = useAppSelector(state => state.userSelection.association);

    function handleFileChange(targetFile: ExportFile) {
        return async function (e: ChangeEvent<HTMLInputElement>) {
            console.log("file change");
            if (e.target.files) {
                const selectedFile = e.target.files[0];
                const exportS3Store = getStore();
                await exportS3Store.uploadFile(selectedFile, targetFile);
                await listFiles();
            }
        };
    }

    async function exportPrep() {
        const jiraTicket = new JiraTicketId(ticketNumber);
        const exportS3Store = getStore();
        const rowsFileUrl = await exportS3Store.getS3UrlIfExists("rows.csv");
        if (!rowsFileUrl) {
            alert("Rows file is required. Mappings is optional.");
            return;
        }
        const mappingsFileUrl = await exportS3Store.getS3UrlIfExists("mappings.csv");
        const exportTemplateParams = {
            type: "dynamic",
            rowsFileUrl,
            mappingsFileUrl,
        };
        const profileFilter: ProfileExportFilter = {
            employmentStatus: employmentStatus === "ALL" ? undefined : employmentStatus,
            targetProfilePks: idPassports.length > 0 ? idPassports : undefined,
        };
        const entriesFileUrl = await exportS3Store.getS3UrlIfExists("entries.csv");
        const entriesInput = {
            entriesFileUrl,
        };
        return { exportTemplateParams, entriesInput, profileFilter, jiraTicket };
    }

    async function handleAsyncProfileSubmit() {
        const prep = await exportPrep();
        if (!prep) {
            return;
        }
        const { exportTemplateParams, profileFilter } = prep;
        const validEmailDomains = ["peopleflow", "worklight"];
        if (email && !validEmailDomains.some(domain => email.includes(domain))) {
            alert(`Email ${email} not one of ${validEmailDomains.join(", ")}.`);
            return;
        }
        flashMessage("Submitting request...");
        await submitExportRequest(
            selectedAssociation,
            [email],
            exportTemplateParams,
            profileFilter.employmentStatus,
            profileFilter.targetProfilePks,
        );
        flashMessage("Submitted. Check your email for the result or Slack for errors");
    }
    async function handleSyncSubmit() {
        const prep = await exportPrep();
        if (!prep) {
            return;
        }
        const { exportTemplateParams, profileFilter } = prep;
        const resultDir = getStore().getS3DirUrl();
        flashMessage(`Submitting request for ...`);
        await submitSlsProfileExportRequest(
            selectedAssociation,
            exportTemplateParams,
            profileFilter,
            resultDir,
            format,
        );
        flashMessage("Submitted. Check dev tools for more info.");
        await listFiles();
    }
    async function removeFile(fileName: ExportFile) {
        await getStore().deleteFile(fileName);
        setFileNames(fileNames.filter(f => f.fileName !== fileName));
    }
    async function downloadFile(fileName: ExportFile) {
        await getStore().downloadFile(fileName);
    }

    return (
        <div className={styles.root}>
            <div className={styles.textbtnrow}>
                <div className={styles.form}>
                    <TextField
                        id="ticketNumber"
                        label="Ticket Number"
                        variant="outlined"
                        onChange={(e: any) => setTicketNumber(e.target.value)}
                        value={ticketNumber}
                    />
                </div>
            </div>
            <div className={styles.files}>
                {fileNames.map(f => {
                    return (
                        <div className={styles.form} key={f.fileName}>
                            <PrettyFileListEntry file={f} />{" "}
                            <CloudDownloadIcon onClick={() => downloadFile(f.fileName)} />{" "}
                            <DeleteForeverIcon onClick={() => removeFile(f.fileName)} />
                        </div>
                    );
                })}
            </div>
            <div>
                <div>
                    Conversion (see{" "}
                    <a href="https://simplygrowza.atlassian.net/wiki/spaces/PEOPLEFLOW/pages/41123841/Import+and+export+templates">
                        SOP
                    </a>{" "}
                    for help)
                </div>
                <div className={styles.textbtnrow}>
                    <div className={styles.form}>Rows</div>
                    <div className={styles.form}>
                        <input
                            ref={rowsFileRef}
                            type="file"
                            onClick={() => {
                                console.log("clicked");
                                if (rowsFileRef.current) {
                                    rowsFileRef.current.value = "";
                                }
                            }}
                            onInput={handleFileChange("rows.csv")}
                        />{" "}
                    </div>
                </div>
                <div className={styles.textbtnrow}>
                    <div className={styles.form}>Mappings</div>
                    <div className={styles.form}>
                        <input
                            ref={mappingsFileRef}
                            type="file"
                            onInput={handleFileChange("mappings.csv")}
                            onClick={() => {
                                console.log("clicked");
                                if (mappingsFileRef.current) {
                                    mappingsFileRef.current.value = "";
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.textbtnrow}>
                <div className={styles.form}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Employment Status</InputLabel>
                        <Select
                            labelId="employmentstatus-select-label"
                            label="Employment status"
                            id="employmentstatus-select"
                            value={employmentStatus}
                            onChange={(e: any) => setEmploymentStatus(e.target.value)}>
                            {employmentStatuses.map(status => {
                                return (
                                    <MenuItem value={status} key={status}>
                                        {status}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div className={styles.form}>or</div>
                <div className={styles.form}>
                    <TextField
                        id="idPassports"
                        label="IdPassports"
                        variant="outlined"
                        multiline={true}
                        rows={4}
                        onChange={(e: any) => {
                            setIdPassports(e.target.value.split("\n").filter((s: string) => s !== ""));
                        }}
                        value={idPassports.join("\n")}
                    />
                </div>
            </div>
            <div></div>
            Export
            <div className={styles.textbtnrow}>
                <div className={styles.form}>
                    <Button variant="contained" onClick={handleSyncSubmit}>
                        Sync Export
                    </Button>
                </div>
                <>
                    <div className={styles.form}>or</div>
                    <div className={styles.textbtnrow}>
                        <div className={styles.form}>
                            <TextField
                                id="email"
                                label="Email"
                                variant="outlined"
                                onChange={(e: any) => setEmail(e.target.value)}
                                value={email}
                            />
                        </div>
                        <div className={styles.form}>
                            <Button variant="contained" onClick={handleAsyncProfileSubmit}>
                                Async Export
                            </Button>
                        </div>
                    </div>
                </>{" "}
            </div>
            <div className={styles.form}>
                <div>{someMessage}</div>
            </div>
        </div>
    );
}

export default Exports;
