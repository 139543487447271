import { createSlice } from "@reduxjs/toolkit";
import { LS_EmployeeProfile } from "../models/existing/lsModels";

import {
  queryProfile,
  queryProfileIndexes,
} from "../providers/lambda-services";

interface ProfileState {
  index: any[];
  lastFetchedProfile: LS_EmployeeProfile | {};
  profileLoading: boolean;
  profileIndexLoading: boolean;
}

const initialState: ProfileState = {
  index: [],
  lastFetchedProfile: {},
  profileLoading: false,
  profileIndexLoading: false,
};

const profiles = createSlice({
  name: "profiles",
  initialState,
  reducers: {
    setIndex: (state, action) => {
      state.index = action.payload;
    },
    setLastFetchedProfile: (state, action) => {
      state.lastFetchedProfile = action.payload;
    },
    setProfileLoadingStatus: (state, action) => {
      state.profileLoading = action.payload;
    },
  },
});

export function thunkFetchProfile(association: string, idPassport: string) {
  return async (dispatch: any) => {
    dispatch(profilesActions.setProfileLoadingStatus(true));
    const fetchedProfile = await queryProfile(association, idPassport);
    dispatch(profilesActions.setLastFetchedProfile(fetchedProfile));
    dispatch(profilesActions.setProfileLoadingStatus(false));
  };
}

export function thunkFetchProfileIndexes(association: string) {
  return async (dispatch: any) => {
    const profileIndexes = await queryProfileIndexes(association);
    dispatch(profilesActions.setIndex(profileIndexes));
  };
}

export const profilesActions = profiles.actions;

export default profiles.reducer;
