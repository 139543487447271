import _ from "lodash";

import { z } from "zod";
import { customAlphabet } from "nanoid";
import { nolookalikesSafe } from "nanoid-dictionary";

const nanoid = customAlphabet(nolookalikesSafe);

export const RecordStatusEnumSchema = z.enum(["PENDING", "RUNNING", "SUCCESS", "FAILURE"]);
export type RecordStatusEnum = z.infer<typeof RecordStatusEnumSchema>;

export const RecordTypeEnumSchema = z.enum([
    "TASK_EXTRACT_PF_PROFILES",
    "TASK_EXTRACT_ADAGIN_PROFILES",
    "TASK_EXTRACT_AGRIGISTICS_PROFILES",
    "TASK_EXTRACT_PAYSPACE_PROFILES",
    "TASK_ADAGIN_PF_STATS",
    "TASK_PAYSPACE_PF_STATS",
    "TASK_ADAGIN_AUTO_CONFIG",
    "TASK_AGRIGISTICS_AUTO_CONFIG",
    "TASK_PAYSPACE_PF_SYNC",
    "TASK_AGRIGISTICS_PF_SYNC",
    "TASK_ADAGIN_PF_SYNC",
    "TASK_DATADOG_DEV_MESSAGES",
    "JOB_AD_HOC",
    "JOB_NIGHTLY",
    "JOB_LOCAL",
]);

export type RecordTypeEnum = z.infer<typeof RecordTypeEnumSchema>;

const RecordLogSchema = z.object({
    timestamp: z.string().datetime(),
    message: z.string(),
    actor: z.string(),
});

const BaseRecordSchema = z.object({
    pk: z.string(),
    sk: z.string(),
    params: z.object({}).default({}),
    status: RecordStatusEnumSchema,
    logs: z.array(RecordLogSchema).default([]),
    parents: z
        .array(
            z.object({
                kind: RecordTypeEnumSchema,
                status: RecordStatusEnumSchema,
            }),
        )
        .default([]),
});

const TaskSchema_EXTRACT_PF_PROFILES = BaseRecordSchema.extend({
    kind: z.literal(RecordTypeEnumSchema.Values.TASK_EXTRACT_PF_PROFILES),
});

const TaskSchema_EXTRACT_ADAGIN_PROFILES = BaseRecordSchema.extend({
    kind: z.literal(RecordTypeEnumSchema.Values.TASK_EXTRACT_ADAGIN_PROFILES),
});

const TaskSchema_EXTRACT_AGRIGISTICS_PROFILES = BaseRecordSchema.extend({
    kind: z.literal(RecordTypeEnumSchema.Values.TASK_EXTRACT_AGRIGISTICS_PROFILES),
});

const TaskSchema_EXTRACT_PAYSPACE_PROFILES = BaseRecordSchema.extend({
    kind: z.literal(RecordTypeEnumSchema.Values.TASK_EXTRACT_PAYSPACE_PROFILES),
    params: z.object({
        effectiveDate: z.string(),
        weeks: z.number().optional(),
    }),
});
export type Task_EXTRACT_PAYSPACE_PROFILES = z.infer<typeof TaskSchema_EXTRACT_PAYSPACE_PROFILES>;

const TaskSchema_COMBINE_ADAGIN_PF_PROFILES = BaseRecordSchema.extend({
    kind: z.literal(RecordTypeEnumSchema.Values.TASK_ADAGIN_PF_STATS),
});

const TaskSchema_PAYSPACE_PF_STATS = BaseRecordSchema.extend({
    kind: z.literal(RecordTypeEnumSchema.Values.TASK_PAYSPACE_PF_STATS),
});

const TaskSchema_PAYSPACE_PF_SYNC = BaseRecordSchema.extend({
    kind: z.literal(RecordTypeEnumSchema.Values.TASK_PAYSPACE_PF_SYNC),
});

const TaskSchema_AGRIGISTICS_PF_SYNC = BaseRecordSchema.extend({
    kind: z.literal(RecordTypeEnumSchema.Values.TASK_AGRIGISTICS_PF_SYNC),
});

const TaskSchema_ADAGIN_PF_SYNC = BaseRecordSchema.extend({
    kind: z.literal(RecordTypeEnumSchema.Values.TASK_ADAGIN_PF_SYNC),
});

const TaskSchema_ADAGIN_AUTO_CONFIG = BaseRecordSchema.extend({
    kind: z.literal(RecordTypeEnumSchema.Values.TASK_ADAGIN_AUTO_CONFIG),
});

const TaskSchema_AGRIGISTICS_AUTO_CONFIG = BaseRecordSchema.extend({
    kind: z.literal(RecordTypeEnumSchema.Values.TASK_AGRIGISTICS_AUTO_CONFIG),
});

const TaskSchema_DATADOG_DEV_MESSAGES = BaseRecordSchema.extend({
    kind: z.literal(RecordTypeEnumSchema.Values.TASK_DATADOG_DEV_MESSAGES),
    params: z.object({
        refStartDate: z.string().datetime(),
        refEndDate: z.string().datetime(),
    }),
});

const TaskSchema = z.union([
    TaskSchema_EXTRACT_PF_PROFILES,
    TaskSchema_EXTRACT_ADAGIN_PROFILES,
    TaskSchema_EXTRACT_AGRIGISTICS_PROFILES,
    TaskSchema_EXTRACT_PAYSPACE_PROFILES,
    TaskSchema_DATADOG_DEV_MESSAGES,
    TaskSchema_COMBINE_ADAGIN_PF_PROFILES,
    TaskSchema_PAYSPACE_PF_STATS,
    TaskSchema_PAYSPACE_PF_SYNC,
    TaskSchema_AGRIGISTICS_PF_SYNC,
    TaskSchema_ADAGIN_PF_SYNC,
    TaskSchema_ADAGIN_AUTO_CONFIG,
    TaskSchema_AGRIGISTICS_AUTO_CONFIG,
]);

const JobSchema_AD_HOC = BaseRecordSchema.extend({
    kind: z.literal(RecordTypeEnumSchema.Values.JOB_AD_HOC),
    params: z.object({
        task: TaskSchema,
    }),
});

const JobSchema_NIGHTLY = BaseRecordSchema.extend({
    kind: z.literal(RecordTypeEnumSchema.Values.JOB_NIGHTLY),
});

const JobSchema_CUSTOM = BaseRecordSchema.extend({
    kind: z.literal(RecordTypeEnumSchema.Values.JOB_LOCAL),
});

const JobSchema = z.union([JobSchema_AD_HOC, JobSchema_NIGHTLY, JobSchema_CUSTOM]);

export const RecordSchema = z.union([TaskSchema, JobSchema]);
export type ETLRecord = z.infer<typeof RecordSchema>;
export type RecordType = "JOB" | "TASK";

const RecordIdSchema = z.object({
    jobKind: RecordTypeEnumSchema,
    kind: RecordTypeEnumSchema,
    jobGroupId: z.string(),
    jobUnixMs: z.number(),
    jobNanoId: z.string(),
});

export type RecordId = z.infer<typeof RecordIdSchema>;

export class EtlEntry {
    private record: ETLRecord;
    private recordType: RecordType;
    private id: RecordId;
    constructor(record: ETLRecord) {
        this.record = record;
        this.recordType = EtlEntry.parseRecordType(this.record.kind);
        this.id = this.parseRecordId();
    }

    private static parseRecordType(kind: RecordTypeEnum): RecordType {
        if (kind.startsWith("TASK")) {
            return "TASK";
        }
        if (kind.startsWith("JOB")) {
            return "JOB";
        }
        throw new Error(`Invalid record kind: ${kind}`);
    }

    static buildRecordKey(id: RecordId) {
        switch (EtlEntry.parseRecordType(id.kind)) {
            case "JOB": {
                if (id.jobKind !== id.kind) {
                    throw new Error(`Cannot build job key with different job kind: ${id.jobKind} vs ${id.kind}`);
                }
                return {
                    pk: id.jobGroupId,
                    sk: `${id.jobKind}#${id.jobUnixMs}_${id.jobNanoId}`,
                };
            }
            case "TASK": {
                return {
                    pk: `${id.jobGroupId}#${id.jobKind}#${id.jobUnixMs}_${id.jobNanoId}`,
                    sk: id.kind,
                };
            }
        }
    }

    static generateNanoId(): string {
        return nanoid(4);
    }

    private parseRecordId(): RecordId {
        const kind = this.record.kind;
        switch (EtlEntry.parseRecordType(kind)) {
            case "JOB": {
                const jobGroupId = this.record.pk;
                const [jobKindStr, jobId] = this.record.sk.split("#");
                const jobKind = jobKindStr as RecordTypeEnum;
                const [jobUnixMsStr, jobNanoId] = jobId.split("_");
                return { jobGroupId, kind, jobKind, jobUnixMs: Number(jobUnixMsStr), jobNanoId };
            }
            case "TASK": {
                const [jobGroupId, jobKindStr, jobId] = this.record.pk.split("#");
                const jobKind = jobKindStr as RecordTypeEnum;
                const [jobUnixMsStr, jobNanoId] = jobId.split("_");
                return { jobGroupId, kind, jobKind, jobUnixMs: Number(jobUnixMsStr), jobNanoId };
            }
        }
    }

    jobDateTime() {
        return new Date(this.id.jobUnixMs).toISOString();
    }

    getRecord() {
        return _.cloneDeep(this.record);
    }

    getRecordType(): RecordType {
        return _.cloneDeep(this.recordType);
    }

    jobsPk() {
        const { jobGroupId } = this.getId();
        return jobGroupId;
    }
    tasksPk() {
        const { jobGroupId, jobKind, jobUnixMs, jobNanoId } = this.getId();
        return `${jobGroupId}#${jobKind}#${jobUnixMs}_${jobNanoId}`;
    }
    jobPk() {
        const { jobGroupId, jobKind, jobUnixMs, jobNanoId } = this.getId();
        return {
            pk: jobGroupId,
            sk: `${jobKind}#${jobUnixMs}_${jobNanoId}`,
        };
    }

    getId(): RecordId {
        return _.cloneDeep(this.id);
    }

    s3TaskBasePath(kindOverride?: RecordTypeEnum) {
        const { jobGroupId, jobKind, jobUnixMs, jobNanoId, kind } = this.getId();
        if (kindOverride) {
            return `jobs/${jobGroupId}/${jobKind}/${jobUnixMs}_${jobNanoId}/${kindOverride}`;
        }
        return `jobs/${jobGroupId}/${jobKind}/${jobUnixMs}_${jobNanoId}/${kind}`;
    }
}
