import { useState, useEffect } from "react";
import styles from "./About.module.css";

function About() {
    return (
        <div className={styles.root}>
            <div className={styles.form}>
                <div className={styles.blog}>
                    <div>
                        "The Hammer" is our Peopleflow tool for <b>internal use only</b>. The goal of this tool is to
                        allow us to quickly develop new ideas that we can test with little regard to how it looks. The
                        image below illustrates this. This is why we <b>never</b> share this site with clients.
                    </div>
                    <br />
                    <div>
                        <img src="./hammer.JPG" alt="logo" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
