import { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { useAppSelector, useAppDispatch } from "../hooks";
import { userSelectionActions } from "../store/userSelection";
import { thunkFetchProfile } from "../store/profiles";

import { docCheck } from "../providers/lambda-services";

import styles from "./RawProfile.module.css";
import JsonDisplay from "./JsonDisplay";

function RawProfile() {
    const [selectedDocumentKey, setSelectedDocumentKey] = useState<string>();
    const [documentExists, setDocumentExists] = useState<boolean | undefined>(undefined);
    const dispatch = useAppDispatch();
    const selectedIdPassport = useAppSelector(state => state.userSelection.idPassport);
    const selectedAssociation = useAppSelector(state => state.userSelection.association);
    const lastFetchedProfile = useAppSelector(state => state.profiles.lastFetchedProfile);

    const profileLoading = useAppSelector(state => state.profiles.profileLoading);

    function onIdInput(event: any) {
        dispatch(userSelectionActions.setIdPassport(event.target.value));
    }

    async function fetchProfilesHandler() {
        dispatch(thunkFetchProfile(selectedAssociation, selectedIdPassport));
    }
    async function selectIndexTool() {
        dispatch(dispatch(userSelectionActions.setTool("profileIndex")));
    }

    async function checkDocumentExists() {
        const result = await docCheck(selectedAssociation, selectedIdPassport, selectedDocumentKey);
        setDocumentExists(result);
        setTimeout(() => {
            setDocumentExists(undefined);
        }, 3000);
        console.log(result);
    }

    return (
        <div className={styles.root}>
            <div className={styles.form}>
                <div className={styles.button}>
                    <TextField
                        id="idPassport"
                        label="idPassport"
                        variant="outlined"
                        onChange={onIdInput}
                        value={selectedIdPassport}
                    />
                </div>
                <div className={styles.button}>
                    <Button variant="contained" onClick={fetchProfilesHandler}>
                        Fetch
                    </Button>
                </div>
                <div className={styles.button}>
                    <Button variant="outlined" onClick={selectIndexTool}>
                        Index
                    </Button>
                </div>
            </div>
            <div className={styles.form}>
                <div className={styles.button}>
                    <div className={styles.longtext}>
                        <TextField
                            fullWidth
                            id="documentKey"
                            label="documentKey"
                            variant="outlined"
                            onChange={event => setSelectedDocumentKey(event.target.value)}
                            value={selectedDocumentKey}
                        />
                    </div>
                </div>
                <div className={styles.button}>
                    <Button variant="contained" onClick={checkDocumentExists}>
                        Check
                    </Button>
                </div>
                {documentExists !== undefined && (
                    <div className={styles.button}>{documentExists ? "Exists" : "Does not exist"}</div>
                )}
            </div>
            {profileLoading && (
                <div>
                    Fetching {selectedIdPassport} for {selectedAssociation}...
                </div>
            )}
            {!profileLoading && (
                <div>
                    <JsonDisplay data={lastFetchedProfile}></JsonDisplay>
                </div>
            )}
        </div>
    );
}

export default RawProfile;
