import { useAppSelector, useAppDispatch } from "../hooks";

import styles from "./ProfileIndexStats.module.css";

function calcProfileIndexStats(profileIndexes: Array<any>) {
  const total = profileIndexes.length;
  const trashStatus = profileIndexes.reduce((acc, index) => {
    const status = index.trashStatus || "missing";
    acc[status] = acc[status] || 0;
    acc[status] += 1;
    return acc;
  }, {});
  const cohorts = profileIndexes.reduce((acc, index) => {
    function validValues(cohorts: string[] | undefined) {
      if (!cohorts) {
        return ["missing"];
      }
      if (cohorts.length === 0) {
        return ["empty"];
      }
      return cohorts;
    }
    for (const cohort of validValues(index.cohorts)) {
      acc[cohort] = acc[cohort] || 0;
      acc[cohort] += 1;
    }
    return acc;
  }, {});
  return { total, trashStatus, cohorts };
}

function ProfileIndexStats() {
  const profileIndexes = useAppSelector((state) => state.profiles.index);
  const stats = calcProfileIndexStats(profileIndexes);

  return (
    <div>
      <pre className={styles.json} id="json">
        {JSON.stringify(stats, undefined, 2)}
      </pre>
    </div>
  );
}

export default ProfileIndexStats;
