import { ChangeEvent, useState, useEffect, useRef } from "react";
import { TextField, Button } from "@mui/material";
import styles from "./Exports.module.css";
import { useAppSelector, useAppDispatch } from "../hooks";
import { userSelectionActions } from "../store/userSelection";
import { S3InternalBlobStore, JiraTicketId, FileListEntry } from "../providers/s3";
import { UnifyS3Store } from "./Unify";
import PrettyFileListEntry from "./FileEntry";

// Icons
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

import { submitSlsEntriesTransformRequest } from "../providers/lambda-services";

const transformFiles = [
    "rows.csv",
    "mappings.csv",
    "entries.csv",
    "entries.json",
    "entriesTransform_dynamic.csv",
] as const;
export type TransformFile = (typeof transformFiles)[number];
export class TransformS3Store extends S3InternalBlobStore<TransformFile> {
    constructor(client: string, ticket: JiraTicketId) {
        super("transform", client, ticket);
    }
}

function TransformRecords() {
    const dispatch = useAppDispatch();
    const [fileNames, setFileNames] = useState<FileListEntry<TransformFile>[]>([]);
    const [format, setFormat] = useState<string>("csv");
    const [someMessage, setSomeMessage] = useState<string>();
    const [someMessageTimeout, setSomeMessageTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined);
    const rowsFileRef = useRef<HTMLInputElement>(null);
    const mappingsFileRef = useRef<HTMLInputElement>(null);
    const entriesFileRef = useRef<HTMLInputElement>(null);
    const ticketNumber = useAppSelector(state => state.userSelection.ticketNumber);
    function setTicketNumber(ticketNumber: string) {
        dispatch(userSelectionActions.setTicketNumber(ticketNumber));
    }

    useEffect(() => {
        if (!ticketNumber || !JiraTicketId.isValidId(ticketNumber)) {
            return;
        }
        // Update file list after 1 second of inactivity
        const timeOutId = setTimeout(() => {
            listFiles();
        }, 1000);
        return () => clearTimeout(timeOutId);
    }, [ticketNumber]);

    function getStore() {
        return new TransformS3Store(selectedAssociation, new JiraTicketId(ticketNumber));
    }

    async function listFiles() {
        const s3Store = getStore();
        const files = await s3Store.listFileNames();
        console.log(files);
        setFileNames(files);
    }

    async function setEntriesInputFromUnifyStore() {
        const unifyStore = new UnifyS3Store(selectedAssociation, new JiraTicketId(ticketNumber));
        const unifyResultFileName = "result.csv";
        const fileExists = await unifyStore.fileExists(unifyResultFileName);
        if (!fileExists) {
            alert("No results.csv found from the Unify tool");
            return;
        }
        const unifyResultKey = unifyStore.anyFileKey(unifyResultFileName);
        await getStore().copyFile(unifyResultKey, "entries.csv");
        await listFiles();
        flashMessage("Copied entries.csv from Unify");
    }

    function flashMessage(message: string) {
        clearTimeout(someMessageTimeout);
        setSomeMessage(message);
        const timeout = setTimeout(() => {
            setSomeMessage(undefined);
        }, 5000);
        setSomeMessageTimeoutId(timeout);
    }

    const selectedAssociation = useAppSelector(state => state.userSelection.association);

    function handleFileChange(targetFile: TransformFile) {
        return async function (e: ChangeEvent<HTMLInputElement>) {
            console.log("file change");
            if (e.target.files) {
                const selectedFile = e.target.files[0];
                const s3Store = getStore();
                await s3Store.uploadFile(selectedFile, targetFile);
                await listFiles();
            }
        };
    }

    async function exportPrep() {
        const jiraTicket = new JiraTicketId(ticketNumber);
        const s3Store = getStore();
        const rowsFileUrl = await s3Store.getS3UrlIfExists("rows.csv");
        if (!rowsFileUrl) {
            alert("Rows file is required. Mappings is optional.");
            return;
        }
        const mappingsFileUrl = await s3Store.getS3UrlIfExists("mappings.csv");
        const exportTemplateParams = {
            type: "dynamic",
            rowsFileUrl,
            mappingsFileUrl,
        };
        const entriesFilepath = await s3Store.getS3UrlIfExists("entries.csv");
        return { exportTemplateParams, entriesFilepath, jiraTicket };
    }

    async function handleSyncSubmit() {
        const prep = await exportPrep();
        if (!prep) {
            return;
        }
        const { exportTemplateParams, entriesFilepath } = prep;
        const resultDir = getStore().getS3DirUrl();
        flashMessage(`Submitting request for...`);
        await submitSlsEntriesTransformRequest(exportTemplateParams, entriesFilepath, resultDir, format);
        flashMessage("Submitted. Check dev tools for more info.");
        await listFiles();
    }
    async function removeFile(fileName: TransformFile) {
        await getStore().deleteFile(fileName);
        setFileNames(fileNames.filter(f => f.fileName !== fileName));
    }
    async function downloadFile(fileName: TransformFile) {
        await getStore().downloadFile(fileName);
    }

    return (
        <div className={styles.root}>
            <div className={styles.textbtnrow}>
                <div className={styles.form}>
                    <TextField
                        id="ticketNumber"
                        label="Ticket Number"
                        variant="outlined"
                        onChange={(e: any) => setTicketNumber(e.target.value)}
                        value={ticketNumber}
                    />
                </div>
            </div>
            <div className={styles.files}>
                {fileNames.map(f => {
                    return (
                        <div className={styles.form} key={f.fileName}>
                            <PrettyFileListEntry file={f} />{" "}
                            <CloudDownloadIcon onClick={() => downloadFile(f.fileName)} />{" "}
                            <DeleteForeverIcon onClick={() => removeFile(f.fileName)} />
                        </div>
                    );
                })}
            </div>
            <div>
                <div>
                    Conversion (see{" "}
                    <a href="https://simplygrowza.atlassian.net/wiki/spaces/PEOPLEFLOW/pages/41123841/Import+and+export+templates">
                        SOP
                    </a>{" "}
                    for help)
                </div>
                <div>
                    <div className={styles.textbtnrow}>
                        <div className={styles.form}>Entries</div>
                        <div className={styles.form}>
                            <Button variant="contained" size="small" onClick={setEntriesInputFromUnifyStore}>
                                From Unify
                            </Button>
                        </div>
                        <div className={styles.form}>or</div>
                        <div className={styles.form}>
                            <input
                                ref={entriesFileRef}
                                type="file"
                                onInput={handleFileChange("entries.csv")}
                                onClick={() => {
                                    console.log("clicked");
                                    if (entriesFileRef.current) {
                                        entriesFileRef.current.value = "";
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.textbtnrow}>
                    <div className={styles.form}>Rows</div>
                    <div className={styles.form}>
                        <input
                            ref={rowsFileRef}
                            type="file"
                            onClick={() => {
                                console.log("clicked");
                                if (rowsFileRef.current) {
                                    rowsFileRef.current.value = "";
                                }
                            }}
                            onInput={handleFileChange("rows.csv")}
                        />{" "}
                    </div>
                </div>
                <div className={styles.textbtnrow}>
                    <div className={styles.form}>Mappings</div>
                    <div className={styles.form}>
                        <input
                            ref={mappingsFileRef}
                            type="file"
                            onInput={handleFileChange("mappings.csv")}
                            onClick={() => {
                                console.log("clicked");
                                if (mappingsFileRef.current) {
                                    mappingsFileRef.current.value = "";
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.textbtnrow}>
                <div className={styles.form}>
                    <Button variant="contained" onClick={handleSyncSubmit}>
                        Transform
                    </Button>
                </div>
            </div>
            <div className={styles.form}>
                <div>{someMessage}</div>
            </div>
        </div>
    );
}

export default TransformRecords;
