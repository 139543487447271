import { useState, useEffect } from "react";
import {
  queryProfilesBilling,
} from "../providers/lambda-services";
import { useAppSelector } from "../hooks";

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

export default function Billing() {
  const [profileBillingTrend, setProfileBillingTrends] = useState<any>({
    date: "",
    employees: [],
    candidates: [],
  });
  const selectedAssociation = useAppSelector(
    (state) => state.userSelection.association
  );

  async function getTrends() {
    const trends = await queryProfilesBilling(selectedAssociation);
    console.log(trends);
    setProfileBillingTrends(trends.map((entry) => {
        const {employees, candidates} = entry;
        return {
            date: new Date(entry.unixMs).toISOString(), employees: employees?.quantity, candidates: candidates?.quantity };
    }));
  }

  useEffect(() => {
    getTrends();
  }, [selectedAssociation]);

  return (
    <>
      <ResponsiveContainer width="100%" aspect={3}>
        <AreaChart
          data={profileBillingTrend}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="candidates"
            stroke="#bfbfbf"
            fill="#bfbfbf"
          />
          <Area
            type="monotone"
            dataKey="employees"
            stroke="#8884d8"
            fill="#8884d8"
          />
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
}
