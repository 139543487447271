import { Operation } from "fast-json-patch";

export const IMPORT_ACTIONS = [
    "NEW_PROFILE",
    "ADD_FIELDS",
    "REPLACE_FIELDS",
    "REMOVE_FIELDS",
    "TERMINATE",
    "EMPLOY",
    "TRASH",
];
export type ImportAction = (typeof IMPORT_ACTIONS)[number];

const INVALIDATION_REASONS = ["MISSING_ID_PASSPORT", "MISSING_REQUIRED_KEY", "UNKNOWN_KEY", "INVALID_SELECTION"];
type InvalidationReason = (typeof INVALIDATION_REASONS)[number];

const PLAN_PROBLEM_REASONS = [
    "DIRECT_MODIFICATION",
    "ID_PASSPORT_MISMATCH",
    "MISSING_EMPLOYMENT_STATUS",
    "MISSING_COHORTS",
    "MISSING_TRASH_STATUS",
    "PROFILE_ALREADY_EXISTS",
    "PROFILE_DOES_NOT_EXIST",
    "NOTHING_TO_DO",
    "CORRUPT_EXISTING_TERMINATION_STATE",
    "ALREADY_TERMINATED",
    "CANDIDATE_ON_IMPORT",
    "ALREADY_EMPLOYED",
    "UNEXPECTED_ERROR",
    "INSUFFICIENT_INPUT",
];

type PlanProblemReason = (typeof PLAN_PROBLEM_REASONS)[number];

export type Reason = InvalidationReason | PlanProblemReason;

type IPlanFilter = {
    operation?: Operation;
    validation?: Reason[];
    ignoreRecordsWithTheseWarnings?: Reason[];
    ignoreValid?: boolean;
    onlyInvalidations?: Reason[];
    generalDataSearch?: { key: string; value: any };
};

export type BulkActionConfig = {
    action: ImportAction;
    options: any;
    filter: IPlanFilter;
    allowablePlanWarningReasons: Reason[];
    caseInsensitive?: boolean;
};

export class BulkActionConfigBuilder {
    private config: BulkActionConfig;
    constructor(seedConfig?: BulkActionConfig) {
        if (seedConfig) {
            this.config = JSON.parse(JSON.stringify(seedConfig));
            return;
        }
        this.config = {
            action: "NEW_PROFILE",
            options: {
                trashStatus: "NONE",
            },
            allowablePlanWarningReasons: ["INVALID_SELECTION", "UNKNOWN_KEY", "DIRECT_MODIFICATION"],
            filter: {
                ignoreRecordsWithTheseWarnings: ["ALREADY_EMPLOYED", "NOTHING_TO_DO", "PROFILE_ALREADY_EXISTS"],
            },
        };
    }

    withAction(action: ImportAction): BulkActionConfigBuilder {
        this.config.action = action;
        return this;
    }
    withCohorts(cohorts: string[]): BulkActionConfigBuilder {
        this.config.options.cohorts = cohorts;
        return this;
    }
    withEmploymentStatus(employmentStatus: string): BulkActionConfigBuilder {
        this.config.options.employmentStatus = employmentStatus;
        return this;
    }
    withFilterOperation(operation: Operation): BulkActionConfigBuilder {
        this.config.filter.operation = operation;
        return this;
    }
    build(): BulkActionConfig {
        return this.config;
    }
}
